import { useTheme, useMediaQuery } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();
    const css = {
        sxBoxWrap: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            height: matches ? '1.75rem' : '1.3125rem',
        },
        sxBoxOverlay: {
            'backgroundColor': palette.clouds,
            'border': `0.0625rem solid ${palette.clouds}`,
            'top': 0,
            'left': 0,
            'width': '100%',
            'height': '100%',
            'color': palette.dark,
            'position': 'absolute',
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            '&.left': {
                borderRadius: '0.25rem 0 0 0.25rem',
            },
            '&.right': {
                borderRadius: '0 0.25rem 0.25rem 0',
            },
        },
        sxText: {
            textTransform: 'uppercase',
            fontSize: matches ? '0.75rem' : '0.625rem',
            fontWeight: typography.fontWeightMedium || 500,
            whiteSpace: 'nowrap',
        },
    };

    return { css };
};
