import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

import { PeriodButtonProps } from '@interfaces';

import { Label } from './styles';
import { sx } from './sx';

const PeriodButton: React.FC<PeriodButtonProps> = ({
    id, period, children,
}: PeriodButtonProps) => {
    const { css } = sx();
    const { palette } = useTheme();
    return (
        <Box sx={{ ...css.sxBoxWrap }}>
            <Label backgroundColor={palette.bluePortal} htmlFor={id}>
                {period}
                {children}
                <Box sx={{ ...css.sxBoxOverlay }}
                    className='overlay'>
                    <Typography
                        sx={{ ...css.sxText }}
                        variant='caption'>
                        {period}
                    </Typography>
                </Box>
            </Label>
        </Box>
    );
};

export { PeriodButton };
