import React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { Points, ProductInfo } from '@components';

import { Status } from './components/status';
import { Image } from './styles';
import { sx } from './sx';

const OrderDetails: React.FC = () => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxBoxContainer }}>
            <ProductInfo
                image='https://cdn.discordapp.com/attachments/1044241266134745202/1082652690439487568/mc.png'
                name={'Wap'}
                description={
                    'Lavadora de Alta Pressão Wap Eco Fit 2200 1500psi'}
                points={'371.00'} />
            <Divider
                sx={{ ...css.sxDivider }}
                orientation="horizontal" />
            <Box sx={{ ...css.sxBox }}>
                <Typography variant='h4' sx={{ ...css.sxTitle }}>
                    Parceiro
                </Typography>
                <Image src="https://cdn.discordapp.com/attachments/1044241266134745202/1082264093664215050/2560px-Casas_Bahia_logo.svg.png" alt="" />
            </Box>
            <Divider
                sx={{ ...css.sxDivider }}
                orientation="horizontal" />
            <Box>
                <Typography variant='h4' sx={{ ...css.sxTitleStatus }}>
                Status do pedido
                </Typography>
                <Stack sx={{ ...css.sxStack }}>
                    <Status
                        endItem
                        statusType={'awaitingSend'}
                        date={'03/02/2023 - 15:12'} />
                    <Status
                        statusType={'paid'}
                        date={'03/02/2023 - 15:12'} />
                    <Status
                        statusType={'awaitingPayment'}
                        date={'03/02/2023 - 15:12'} />
                </Stack>
            </Box>
            <Box sx={{ ...css.sxBox }}>
                <Typography variant='h4' sx={{ ...css.sxTitle }}>
                    Total
                </Typography>
                <Points size={'medium'} value={'2275.00'} />
            </Box>
        </Box>
    );
};

export { OrderDetails };
