import { useTheme, useMediaQuery } from '@mui/material';

export const sx = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            borderTop: `0.0625rem solid ${palette.clouds}`,
            padding: matches ? '1.875rem' : '0.9375rem',
        },
        sxBoxTitle: {
            paddingBottom: matches ? '1.25rem' : '0.9375rem',
        },
        sxTitle: {
            fontWeight: typography.fontWeightMedium,
            fontSize: matches ? '1.625rem' : '1.375rem',
            marginBottom: '0.375rem',
        },
        sxLegend: {
            fontWeight: typography.fontWeightLight,
            fontSize: matches ? '0.875rem' : '0.75rem',
        },
        sxBoxFilters: {
            border: `0.0625rem solid ${palette.clouds}`,
        },
        sxCheckbox: {
            '& > .MuiTouchRipple-root': {
                display: 'none',
            },
        },
        sxActiveFiltersText: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '0.75rem',
            paddingRight: '0.5rem',
        },
        sxContainerFilters: {
            '&.mobile': {
                display: 'none',
            },
            '&.active.mobile': {
                display: 'block',
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: palette.common.white,
                height: '100%',
                width: '100%',
                overflow: 'auto',
            },
        },
        sxButtonFiltrage: {
            'width': '100%',
            'textTransform': 'uppercase',
            'backgroundColor': palette.bluePortal,
            'color': palette.common.white,
            'padding': '0 0.9375rem',
            'fontSize': '0.875rem',
            'height': '2.25rem',
            '&:hover': {
                'backgroundColor': palette.bluePortal,
            },
            '& > svg': {
                paddingLeft: '0.5rem',
            },
        },
        sxFilterTitle: {
            fontSize: '0.75rem',
            fontWeight: typography.fontWeightSemiBold,
            textTransform: 'uppercase',
            paddingTop: '0.375rem',
        },
    };
    return { css };
};
