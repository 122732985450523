import React from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import { SectionStoreProps } from '@interfaces';

import { sx } from './sx';

const SectionStore: React.FC<SectionStoreProps> = ({
    title, legend, children,
}: SectionStoreProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxBox }}>
            <Box sx={{ ...css.sxBoxTitle }}>
                <Typography
                    sx={{ ...css.sxTitle }}
                    variant={'h2'}>
                    {title}
                </Typography>
                <Typography
                    sx={{ ...css.sxLegend }}
                    variant={'body1'}>
                    {legend}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};

export { SectionStore };
