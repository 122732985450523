import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const dashboardMenu = [
    {
        title: 'Extrato',
        icon: ['far', 'clipboard-list-check'] as IconProp,
        path: '/extrato',
    },
    {
        title: 'Pedidos',
        icon: ['far', 'boxes-stacked'] as IconProp,
        path: '/meus-pedidos',
    },
    {
        title: 'Meus Dados',
        icon: ['far', 'gear'] as IconProp,
        path: '/meus-dados',
        pathNav: '/meus-dados/perfil',
    },
];
