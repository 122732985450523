import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const matches2 = useMediaQuery('(min-width:600px)');
    const css = {
        sxGridItem: {
            borderRight: `0.0625rem solid ${palette.clouds}`,
            borderTop: `0.0625rem solid ${palette.clouds}`,
            borderBottom: `0.0625rem solid ${palette.clouds}`,
        },
        sxGridContainer: {
            'padding': matches ? '0' : '0 0 0.625rem 0',
            '& > :nth-child(7)': {
                flexBasis: '14.5%',
                maxWidth: '100%',
                display: matches ? 'block' : 'none',
            },
            '& > :nth-child(1)': {
                borderLeft: `0.0625rem solid ${palette.clouds}`,
            },
            '& > :nth-child(4)': {
                borderLeft: matches ? 'none' :
                    `0.0625rem solid ${palette.clouds}`,
                borderTop: matches ?
                    `0.0625rem solid ${palette.clouds}` : 'none',
            },
            '& > :nth-child(5)': {
                borderTop: matches ?
                    `0.0625rem solid ${palette.clouds}` : 'none',
            },
            '& > :nth-child(6)': {
                borderTop: matches ?
                    `0.0625rem solid ${palette.clouds}` : 'none',
            },
        },
        sxBoxSlider: {
            position: 'relative',
            padding: matches2 ? '0 1.875rem' : '0 0.9375rem',
        },
        sxBoxArrow: {
            position: 'absolute',
            height: '100%',
            // eslint-disable-next-line max-len
            background: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 49%, rgba(255,255,255,1) 77%, rgba(255,255,255,1) 100%)',
            width: matches2 ? '5.75rem' : '2.8125rem',
            marginRight: matches2 ?'1.25rem' : '0',
            top: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        sxButtonArrow: {
            'height': '2.75rem',
            'width': matches2 ? '2.75rem' : '1.75rem',
            '& > .MuiTouchRipple-root': {
                display: 'none',
            },
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        sxArrowIcon: {
            fontSize: matches2 ? '1.75rem' : '1.125rem',
            color: palette.dark,
        },
    };

    return { css };
};
