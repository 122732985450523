import { useTheme } from '@mui/material';

interface sxHeaderProps {
    clientColor: string;
    matches: boolean;
}

export const sx = ({
    clientColor, matches,
}: sxHeaderProps) => {
    const { palette, typography } = useTheme();
    const css = {
        sxBoxHeader: {
            borderBottom: `0.0625rem solid ${palette.clouds}`,
        },
        sxContainer: {
            display: 'flex',
            justifyContent: matches ? 'flex-start' : 'space-between',
            alignItems: matches ? 'normal' : 'center',
            padding: matches ? '0 1.5rem' : '0.875rem 0.9375rem',
        },
        sxBoxLogo0: {
            width: '100%',
            maxWidth: matches ? '11.625rem' : '100%',
            marginRight: matches ? '1rem' : 0,
            height: matches ? '5rem' : '4.25rem',
            position: matches ? 'relative' : 'absolute',
            top: matches ? 'auto' : '0',
            bottom: matches ? 'auto' : '0',
            left: matches ? 'auto' : '0',
            right: matches ? 'auto' : '0',
            display: matches ? 'block' : 'flex',
            justifyContent: 'center',
        },
        sxBoxLogo: {
            padding: matches ? '1.125rem 0' : '0.9375rem',
            height: '100%',
            maxWidth: matches ? '11.625rem' : '9.1875rem',
            width: '100%',
            display: 'flex',
            justifyContent: matches ? 'flex-start' : 'center',
        },
        sxTabs: {
            'height': '100%',
            '& > .MuiTabs-scroller': {
                '& > .MuiTabs-indicator': {
                    'height': '0.1875rem',
                    'backgroundColor': clientColor,
                },
                '& > .MuiTabs-flexContainer': {
                    'height': '100%',
                    '& > .MuiTab-root': {
                        'fontSize': '0.875rem',
                        'fontWeight': typography.fontWeightRegular,
                        'color': palette.dark,
                        '&.Mui-selected': {
                            fontWeight: typography.fontWeightSemiBold,
                        },
                    },
                },
            },
        },
        sxStack: {
            paddingLeft: '1rem',
            borderLeft: `0.0625rem solid ${palette.clouds}`,
        },
        sxDivider: {
            borderColor: palette.clouds,
        },
        sxBoxSection: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        sxBoxAvatar: {
            display: 'flex',
            cursor: 'default',
        },
        sxIconButton: {
            padding: '0.625rem',
            borderRadius: 0,
        },
        sxIconDown: {
            fontSize: '0.875rem',
            color: palette.dark,
        },
        sxIconButtonMenu: {
            padding: '0.625rem',
            height: '2.5rem',
            width: '2.5rem',
            borderRadius: 0,
            zIndex: '2',
        },
        sxIconMenu: {
            fontSize: '1.25rem',
            color: palette.dark,
        },
        sxCartIcon: {
            fontSize: '1.125rem',
            color: palette.dark,
            marginRight: '0.375rem',
        },
        sxCartItemsText: {
            fontSize: '0.75rem',
            fontWeight: typography.fontWeightSemiBold,
        },
        sxItemsText: {
            fontSize: '0.75rem',
            fontWeight: typography.fontWeightMedium,
        },
        sxIconButtonMenuUser: {
            padding: '0.625rem',
            borderRadius: 0,
        },
        sxCaretIcon: {
            fontSize: '0.875rem',
            color: palette.dark,
        },
    };

    return { css };
};
