import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const css = {
        sxDivider: {
            borderColor: palette.clouds,
        },
        sxBoxButton: {
            display: 'flex',
            paddingTop: matches ? '1.25rem' : '0',
            justifyContent: matches ? 'flex-end' : 'center',
        },
        sxBoxFakeInput: {
            padding: matches ? '0.9375rem 1.25rem' : '0.625rem 0',
        },
        sxBoxPicture: {
            display: 'flex',
            padding: matches ? '0.625rem 1.25rem' : '0.625rem 0',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        sxMenuItem: {
            fontSize: '0.75rem',
        },
    };

    return { css };
};
