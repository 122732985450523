import { ThemeProvider } from '@mui/material';

import './assets/css/index.scss';
import { theme } from './themes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Routes } from './routes';
import { BrowserRouter } from 'react-router-dom';
import './libs/fontawesome';

import {
    Header,
    Footer,
    FixedMenu,
} from '@components';

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                {window.location.pathname !== '/login' ?
                    <Header
                        clientColor={'#6115DD'}
                        logo={'https://cdn.discordapp.com/attachments/1044241266134745202/1082261363788877845/portal-logo.png'} /> :
                    null}
                <Routes />
                {window.location.pathname !== '/login' ?
                    <>
                        <Footer />
                        <FixedMenu />
                    </> :
                    null}
            </BrowserRouter>
        </ThemeProvider>
    );
}
