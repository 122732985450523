import React from 'react';

import {
    Container,
    Box,
    Grid,
    Stack,
    Divider,
    Typography,
    useMediaQuery,
} from '@mui/material';

import {
    BoxSection,
    CampaignItem,
    DashboardItem,
    QuizItem,
    Extract,
} from './components';

import {
    AvatarGroup,
    Dialog,
    Points,
    CampaignDetails,
} from '@components';
import { CampaignDetailsProps, ModalStaticProps } from '@interfaces';

import { quizData } from '@static/quiz';
import { campaignData } from '@static/campaigns';
import { BackgroundImage } from './styles';
import { sx } from './sx';

const Home: React.FC = () => {
    const matches = useMediaQuery('(min-width:900px)');
    const matches2 = useMediaQuery('(min-width:600px)');
    const [campaignDetail, setCampaignDetail] =
    React.useState<CampaignDetailsProps>({
        campaign: campaignData[0],
    });
    const campaignDetailRef = React.useRef<ModalStaticProps>(null);
    const { css } = sx();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box sx={{ ...css.sxBoxPage }}>
            <Dialog
                className={matches2 ? '' : 'all-screen'}
                title={campaignDetail.campaign.name}
                ref={campaignDetailRef}>
                <CampaignDetails campaign={campaignDetail.campaign} />
            </Dialog>
            <Box sx={{ ...css.sxBoxBanner }}>
                <Box sx={{ ...css.sxBoxBannerImg }}>
                    <BackgroundImage
                        src='https://cdn.discordapp.com/attachments/940342065609449522/1082659538710306816/banner.png'
                        alt='' />
                </Box>
            </Box>
            <Container sx={{ ...css.sxContainer }}>
                <Box sx={{ ...css.sxBoxOverlap }}>
                    <BoxSection>
                        <Box sx={{ ...css.sxBoxUserData }}>
                            <AvatarGroup
                                align={matches ? 'right' : 'bottom'}
                                size={matches ? 'big' : 'small'}
                                title={'Amanda Monteiro'}
                                legend={'Portal Administrador'}
                                src={''} />
                            {!matches &&
                                <Divider
                                    sx={{ ...css.sxDivider }}/>}
                            <Stack
                                direction={matches ? 'row' : 'column'}
                                divider={ matches ?
                                    <Divider
                                        orientation={'vertical'}
                                        sx={{ ...css.sxDividerStack }} /> :
                                    <></>}
                                spacing={2}
                                sx={{ ...css.sxStackUserBox }}>
                                <Box sx={{ ...css.sxBalance }}>
                                    <Typography
                                        variant='h4'
                                        sx={{ ...css.sxBalanceTitle }}>
                                        Saldo disponível
                                    </Typography>
                                    <Points
                                        size={matches ? 'big' : 'medium'}
                                        value={'486'} />
                                    <Typography
                                        variant='caption'
                                        sx={{ ...css.sxUpdateText }}>
                                        Atualizado em 05/02/2023
                                    </Typography>
                                </Box>
                                {!matches &&
                                    <Divider
                                        sx={{ ...css.sxDividerStack }}/>
                                }
                                <Box sx={{ ...css.sxBoxExtract }}>
                                    <Extract clientColor={'#6115DD'} />
                                </Box>
                            </Stack>
                        </Box>
                    </BoxSection>
                </Box>
                <BoxSection
                    title={'Dashboard'}
                    legend={'Navegue por sessões'}>
                    <Grid container spacing={2}>
                        <Grid item
                            lg={3} md={3} sm={6} xs={12}>
                            <DashboardItem
                                link='/campanhas'
                                color={'#6115DD'}
                                title={'Campanhas'}
                                legend={'Participando de 8 campanhas'}
                                icon={['fas', 'chart-simple']} />
                        </Grid>
                        <Grid item
                            lg={3} md={3} sm={6} xs={12}>
                            <DashboardItem
                                color={'#FF947A'}
                                title={'Desempenho'}
                                legend={'Acompanhe seu desempenho'}
                                icon={['fas', 'gauge-circle-bolt']} />
                        </Grid>
                        <Grid item
                            lg={3} md={3} sm={6} xs={12}>
                            <DashboardItem
                                link='/loja'
                                color={'#27AE60'}
                                title={'Catálogos'}
                                legend={'Veja todos os catálogos'}
                                icon={['fas', 'grid-2']} />
                        </Grid>
                    </Grid>
                </BoxSection>
                <BoxSection
                    title={'Quizzes'}
                    legend={'Responda aos quizzes e ganhe pontos'}
                    seeMore>
                    <Grid container spacing={2}>
                        {quizData.map(({ title, legend, questions }) => (
                            <Grid item
                                key={title}
                                lg={3} md={3} sm={6} xs={12}>
                                <QuizItem
                                    color={'#6115DD'}
                                    title={title}
                                    legend={legend}
                                    quantityQuestions={questions} />
                            </Grid>
                        ))}
                    </Grid>
                </BoxSection>
                <BoxSection
                    title={'Campanhas'}
                    legend={'Campanhas em que você esta participando'}
                    seeMoreLink={'/campanhas'}
                    seeMore>
                    <Grid container spacing={2}>
                        {campaignData.map((campaign) => (
                            <Grid item key={campaign.name}
                                lg={3} md={3} sm={6} xs={12}>
                                <CampaignItem
                                    onClick={() => {
                                        setCampaignDetail({
                                            campaign: campaign });
                                        campaignDetailRef.current?.show();
                                    }}
                                    title={campaign.name}
                                    legend={campaign.office}
                                    image={campaign.image} />
                            </Grid>
                        ))}
                    </Grid>
                </BoxSection>
            </Container>
        </Box>
    );
};

export { Home };
