import { Paper, styled } from '@mui/material';

export const Item = styled(Paper)(({ theme }) => ({
    ...{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        textAlign: 'left',
        fontSize: 12,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.dark,
        textTransform: 'capitalize',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    '&.active': {
        backgroundColor: theme.palette.dark,
        color: theme.palette.common.white,
    },
}));

export const CloseIcon = styled('div')`
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6.25rem;
    background-color: ${({ theme }) => theme.palette.carminePink};
    margin-left: 0.375rem;
`;
