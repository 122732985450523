import { useTheme } from '@mui/material';

interface sxProps {
    matches: boolean;
    color: string;
}

export const sx = ({
    matches, color,
}: sxProps) => {
    const { palette, typography } = useTheme();
    const css = {
        sxLink: {
            height: '100%',
            minHeight: '13.875rem',
            width: '100%',
            // maxWidth: matches ? '14.5625rem' : '100%',
            textDecoration: 'none',
        },
        sxBox: {
            height: '100%',
            minHeight: '13.875rem',
            width: '100%',
            // maxWidth: matches ? '14.5625rem' : 'auto',
            borderRadius: '0.1875rem',
            padding: '1.5625rem',
            backgroundColor: `${color}1A`,
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            position: 'relative',
        },
        sxBoxIcon: {
            backgroundColor: color,
            height: '3.75rem',
            width: '3.75rem',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '0.625rem',
        },
        sxIcon: {
            fontSize: '1.5rem',
            color: palette.common.white,
        },
        sxTitle: {
            color: palette.midnight,
            fontSize: '1.125rem',
            fontWeight: typography.fontWeightBold,
            paddingBottom: '0.375rem',
        },
        sxLegend: {
            color: palette.midnight,
            fontSize: '0.75rem',
            fontWeight: typography.fontWeightRegular,
        },
        sxBoxArrow: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            padding: '1.5625rem',
        },
        sxIconArrow: {
            color: color,
        },
    };
    return { css };
};
