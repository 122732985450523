import { useMediaQuery, useTheme } from '@mui/material';

export const sx = ({
    clientColor,
}: {clientColor: string}) => {
    const matches = useMediaQuery('(min-width:900px)');
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
        },
        sxTabs: {
            'height': '100%',
            'padding': matches ? '0 1.875rem' : '0 0.9375rem',
            'borderBottom': `0.0625rem solid ${palette.clouds}`,
            '& > .MuiTabs-scroller': {
                '& > .MuiTabs-indicator': {
                    'height': '0.1875rem',
                    'backgroundColor': clientColor,
                },
                '& > .MuiTabs-flexContainer': {
                    'height': '100%',
                    '& > .MuiTab-root': {
                        'width': matches ? 'auto' : '50%',
                        'padding': matches ? '1.875rem' : '1.0625rem',
                        'fontSize': '1rem',
                        'fontWeight': typography.fontWeightMedium,
                        'textTransform': 'none',
                        '&.Mui-selected': {
                            color: clientColor,
                        },
                    },
                },
            },
        },
        sxDivider: {
            borderColor: palette.clouds,
        },
    };
    return { css };
};
