import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');

    const css = {
        sxBox: {
            backgroundColor: palette.lightBlue,
            minHeight: 'calc(100vh - 225px)',
        },
        sxContainerBox: {
            padding: matches ? '1.875rem 0' : '0.9375rem 0',
        },
        sxBoxMenu: {
            'backgroundColor': palette.common.white,
            'borderRadius': '0.1875rem',
            'boxShadow':
                '0 0.1875rem 0.375rem #00000029',
            'padding': '0.3125rem 0',
            '& > .menu-item': {
                '&:last-child': {
                    'borderBottom': 0,
                },
            },
        },
    };
    return { css };
};
