import React from 'react';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';

interface ControllQuantityProps {
    value: number;
    remove: () => void;
    add: () => void;
}

const ControllQuantity: React.FC<ControllQuantityProps> = ({
    add, remove, value,
}: ControllQuantityProps) => {
    const { css } = sx();
    return (
        <Stack
            sx={{ ...css.sxStack }}
            divider={
                <Divider
                    orientation="vertical"
                    sx={{ ...css.sxDivider }} />
            }
            direction={'row'}>
            <IconButton onClick={remove} sx={{ ...css.sxButton }}>
                <I
                    style={{ ...css.sxIcon }}
                    icon={['fas', 'minus']} />
            </IconButton>
            <Box sx={{ ...css.sxValueBox }}>
                <Typography sx={{ ...css.sxNumber }}>
                    {value}
                </Typography>
            </Box>
            <IconButton onClick={add} sx={{ ...css.sxButton }}>
                <I
                    style={{ ...css.sxIcon }}
                    icon={['fas', 'plus']} />
            </IconButton>
        </Stack>
    );
};

export { ControllQuantity };
