import React from 'react';

import {
    Box,
    Tabs,
    Tab,
    Stack,
    Divider,
    useTheme,
} from '@mui/material';

import { Layout, ProductInfo, ActionButton, Dialog } from '@components';
import { ModalStaticProps } from '@interfaces';

import { OrderDetails } from './details';
import { sx } from './sx';
import { ordersData, tabsData } from '@static/orders';

const Orders: React.FC = () => {
    const { palette } = useTheme();
    const orderDetailRef = React.useRef<ModalStaticProps>(null);
    const { css } = sx({
        clientColor: palette.bluePortal,
    });

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout.AuthenticatedContent
            title='Meus Pedidos'>
            <Dialog title='Detalhes' ref={orderDetailRef}>
                <OrderDetails />
            </Dialog>
            <Box sx={{ ...css.sxBox }}>
                <Tabs
                    value={0}
                    aria-label="basic tabs example"
                    sx={{ ...css.sxTabs }}>
                    {tabsData.map(({ label }) => (
                        <Tab
                            key={label}
                            label={label}
                            onClick={() => null}/>
                    ))}
                </Tabs>
                <Stack
                    divider={
                        <Divider
                            sx={{ ...css.sxDivider }}
                            orientation="horizontal" />
                    }>
                    {ordersData.map((product) => (
                        <ProductInfo
                            key={product.description}
                            image='https://cdn.discordapp.com/attachments/1044241266134745202/1082652690439487568/mc.png'
                            name={product.name}
                            description={product.description}
                            points={product.points}
                            status={product.status as any}>
                            <Box>
                                <ActionButton
                                    text={'Detalhes'}
                                    onClick={() =>
                                        orderDetailRef.current?.show()}
                                    backgroundColor={palette.bluePortal} />
                            </Box>
                        </ProductInfo>
                    ))}
                </Stack>
            </Box>
        </Layout.AuthenticatedContent>
    );
};

export { Orders };
