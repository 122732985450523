import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { MenuProps } from '@interfaces';

import { sx } from './sx';

const MenuItem: React.FC<MenuProps> = ({
    children, title, icon, mode,
    borderType, onClick, color, backgroundColor,
}: MenuProps) => {
    const { palette } = useTheme();
    const { css } = sx({
        backgroundColor: backgroundColor,
    });

    return (
        <Box sx={{
            ...css.sxBox['default'],
            ...css.sxBox[mode],
            ...css.sxBorder[borderType],
        }}
        onClick={onClick}
        className='menu-item'>
            <Box sx={{ ...css.sxBoxContent }}>
                {icon &&
                    <Box sx={{ ...css.sxBoxIcon[mode] }}>
                        <I
                            style={{
                                ...css.sxBackIcon[mode],
                            }}
                            color={color ? color : palette.dark}
                            icon={icon} />
                    </Box>
                }
                <Box>
                    <Typography
                        variant='h3'
                        sx={{
                            textTransform: mode === 'dashboard' ?
                                'uppercase' : 'none',
                            color: color ? color : palette.dark,
                            ...css.sxTitle[mode],
                        }}>
                        {title}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                ...css.sxBoxChildren,
            }}>
                {children}
            </Box>
        </Box>
    );
};

export { MenuItem };
