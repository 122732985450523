import React from 'react';
import { Divider, Stack, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { CampaignDetailsProps } from '@interfaces';

import { Item } from './components/item';
import { sx } from './sx';

const CampaignDetails: React.FC<CampaignDetailsProps> = ({
    campaign,
}: CampaignDetailsProps) => {
    const { css } = sx();
    const { palette } = useTheme();

    const handleStatus = {
        active: {
            color: palette.nephritis,
            message: 'Ativa',
        },
        inactive: {
            color: palette.peach,
            message: 'Inativa',
        },
    };

    return (
        <Stack
            sx={{ ...css.sxStack }}
            divider={
                <Divider
                    sx={{ ...css.sxDivider }}
                    orientation='horizontal'/>
            }>
            <Item keyValue={'Cargo'} value={campaign.office} />
            <Item keyValue={'Data de ecerramento'} value={campaign.endDate} />
            <Item keyValue={'Status'} value={
                <>
                    <I
                        style={{
                            ...css.sxStatusIcon,
                            color: handleStatus[campaign.status].color }}
                        icon={['fas', 'circle']} />
                    {handleStatus[campaign.status].message}
                </>
            } />
            <Item keyValue={'Mecânica'} value={campaign.mecanic} />
            <Item keyValue={'Apuração'} value={campaign.apuration} />
        </Stack>
    );
};

export { CampaignDetails };
