import { useMediaQuery } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxStack: {
            maxWidth: '17.625rem',
        },
        sxName: {
            fontWeight: 600,
            fontSize: '0.875rem',
        },
        sxDescription: {
            fontSize: matches ? '1rem' : '0.75rem',
        },
        sxStatus: {
            paddingBottom: '0.5rem',
        },
        sxStatusIcon: {
            marginRight: '0.25rem',
            fontSize: '0.625rem',
        },
    };

    return { css };
};
