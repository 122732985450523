import { useTheme, useMediaQuery } from '@mui/material';

interface sxProps {
    clientColor: string;
}
export const sx = ({
    clientColor,
}: sxProps) => {
    const matches = useMediaQuery('(min-width:900px)');
    const { palette, typography } = useTheme();
    const css = {
        sxLink: {
            'maxHeight': '5rem',
            'textDecoration': 'none',
            'display': 'flex',
            'backgroundColor': `${clientColor}1A`,
            'color': palette.dark,
            'borderRadius': '0.375rem',
        },
        sxIcon: {
            borderRadius: '0.375rem 0 0 0.375rem',
            marginRight: matches ? '0' : '0.9375rem',
            padding: matches ? '1.625rem 1.125rem' : '0.875rem 0.9375rem',
            backgroundColor: clientColor,
            color: palette.common.white,
            fontSize: matches ? '1.75rem' : '1.5rem',
        },
        sxBoxInfo: {
            'padding': '0 0.9375rem',
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            '&:hover': {
                '.MuiTypography-body1': {
                    textDecoration: 'underline',
                },
            },
        },
        sxTitle: {
            fontWeight: typography.fontWeightMedium || 500,
            fontSize: matches ? '1rem' : '0.875rem',
            textAlign: 'right',
        },
        sxArrowIcon: {
            paddingLeft: '0.375rem',
            fontSize: '0.875rem',
        },
        sxLegend: {
            fontWeight: typography.fontWeightRegular || 400,
            fontSize: matches ? '0.75rem' : '0.625rem',
            color: palette.midnight,
        },
    };
    return { css };
};
