import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:900px)');
    const matches2 = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
            padding: matches ? '1.875rem' : '0.9375rem',
            minHeight: '40.25rem',
        },
        sxTitle: {
            fontWeight: matches ?
                typography.fontWeightBold : typography.fontWeightMedium,
            fontSize: matches ? '1.875rem' : '1.375rem',
        },
        sxStackItems: {
            padding: matches ? '1.875rem 0' : '0.9375rem 0',
        },
        sxBoxItem: {
            border: `1px solid ${palette.clouds}`,
        },
        sxSummaryBox: {
            marginTop: matches ? '1.875rem' : '0',
            padding: matches ? '1.875rem' : '0.9375rem',
            border: `0.0625rem solid ${palette.clouds}`,
        },
        sxLink: {
            textDecoration: 'none',
            marginTop: matches2 ? '1.25rem' : '0.625rem',
        },
        sxLinkText: {
            'color': palette.bluePortal,
            'fontSize': '0.8125rem',
            'fontWeight': typography.fontWeightSemiBold,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        sxBoxActions: {
            display: 'flex',
            marginTop: matches2 ? '0' : '0.625rem',
            flexDirection: 'column',
            alignItems: matches2 ? 'center' : 'start',
            justifyContent: matches2 ? 'start' : 'space-between',
        },
        sxResumeTextBox: {
            borderBottom: `0.0625rem solid ${palette.clouds}`,
            paddingBottom: matches ? '1.5625rem' : '0.9375rem',
        },
        sxResumeText: {
            'fontSize': '1.125rem',
            'fontWeight': matches ? typography.fontWeightBold :
                typography.fontWeightMedium,
        },
        sxSubtotalText: {
            fontSize: '0.875rem',
            fontWeight: matches ? typography.fontWeightSemiBold :
                typography.fontWeightMedium,
        },
        sxSubtotalStack: {
            padding: '1.25rem 0',
        },
    };

    return { css };
};
