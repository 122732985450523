import React from 'react';

import {
    ActionButton,
    CampaignDetails,
    Dialog,
    Layout,
    ProductInfo,
} from '@components';
import { Box, Stack, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';

import { sx } from './sx';
import { CampaignDetailsProps, ModalStaticProps } from '@interfaces';
import { campaignData, tabsData } from '@static/campaigns';

const Campaigns: React.FC = () => {
    const { palette } = useTheme();
    const [valueTab, setValueTab] = React.useState('0');
    const matches = useMediaQuery('(min-width:600px)');
    const [campaignDetail, setCampaignDetail] =
    React.useState<CampaignDetailsProps>({
        campaign: campaignData[0],
    });
    const campaignDetailRef = React.useRef<ModalStaticProps>(null);
    const { css } = sx({
        clientColor: palette.bluePortal,
    });

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout.SimpleBase title='Campanhas'>
            <Dialog
                className={matches ? '' : 'all-screen'}
                title={campaignDetail.campaign.name}
                ref={campaignDetailRef}>
                <CampaignDetails campaign={campaignDetail.campaign} />
            </Dialog>
            <TabContext value={valueTab}>
                <Tabs
                    value={valueTab}
                    aria-label="basic tabs example"
                    sx={{ ...css.sxTabs }}>
                    {tabsData.map(({ label }, i) => (
                        <Tab
                            key={label}
                            value={String(i)}
                            label={label}
                            onClick={() => setValueTab(String(i))}/>
                    ))}
                </Tabs>
                <Box sx={{ ...css.sxBox }}>
                    <TabPanel value={'0'} sx={{ ...css.sxTabPainel }}>
                        <Stack spacing={1}>
                            {campaignData.map((campaign) => (
                                <Box
                                    key={campaign.name}
                                    sx={{ ...css.sxBoxCampaign }}>
                                    <ProductInfo
                                        image='https://cdn.discordapp.com/attachments/1044241266134745202/1082652690439487568/mc.png'
                                        name={campaign.office}
                                        description={campaign.name}>
                                        <Box sx={{ ...css.sxBoxButton }}>
                                            <ActionButton
                                                text={'Detalhes'}
                                                onClick={() => {
                                                    setCampaignDetail({
                                                        campaign: campaign });
                                                    campaignDetailRef.
                                                        current?.show();
                                                }}
                                                backgroundColor={
                                                    palette.bluePortal} />
                                        </Box>
                                    </ProductInfo>
                                </Box>
                            ))}
                        </Stack>
                    </TabPanel>
                    <TabPanel value={'1'} sx={{ ...css.sxTabPainel }}>
                    </TabPanel>
                </Box>
            </TabContext>
        </Layout.SimpleBase>
    );
};

export { Campaigns };
