import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();

    const css = {
        sxBoxContainer: {
            paddingBottom: '0.625rem',
        },
        sxTitle: {
            fontSize: '0.875rem',
            fontWeight: 600,
            padding: '0.625rem 0',
        },
        sxTitleStatus: {
            fontSize: '0.875rem',
            fontWeight: 600,
            padding: '0.625rem 0.9375rem',
        },
        sxBox: {
            padding: '0 0.9375rem',
        },
        sxDivider: {
            borderColor: palette.clouds,
        },
        sxStack: {
            padding: '0.625rem 0.9375rem',
            backgroundColor: '#F4F8F9',
        },
    };

    return { css };
};
