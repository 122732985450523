import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();

    const css = {
        sxRow: {
            'border': 0,
            'backgroundColor': palette.common.white,
            '&:nth-of-type(odd)': {
                backgroundColor: palette.clouds,
            },
        },
        sxCell1: {
            'fontSize': matches ? '0.875rem' : '0.75rem',
            'border': 0,
            'padding': '0.625rem 0.9375rem',
        },
        sxCell2: {
            'fontSize': matches ? '0.875rem' : '0.75rem',
            'fontWeight': typography.fontWeightMedium,
            'border': 0,
            'padding': '0.625rem 0.9375rem',
        },
    };

    return { css };
};
