import React from 'react';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { AvatarGroupProps } from '@interfaces';
import {
    customMargin,
    handleBorder,
    avatarSizes,
    fontSizeTitle,
    fontSizeLegend,
    sx,
} from './sx';

const AvatarGroup: React.FC<AvatarGroupProps> = ({
    align, legend, title, size, src, borderWidth, borderColor, onClick,
}: AvatarGroupProps) => {
    const { typography } = useTheme();
    const { css } = sx();

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            ...css.sxAvatarGroup[align],
        }}>
            <Avatar
                onClick={onClick}
                alt={title}
                src={src}
                sx={{
                    border: handleBorder({
                        borderColor: borderColor,
                        borderWidth: borderWidth,
                    }),
                    ...avatarSizes[size],
                    ...css.sxAvatarDefault }}
            />
            <Box sx={{
                ...css.sxBoxAvatarInfo.default,
                ...css.sxBoxAvatarInfo[align],
                marginTop: customMargin[align][size],
            }}>
                <Typography
                    variant='h2'
                    fontSize={fontSizeTitle[size]}
                    fontWeight={typography.fontWeightMedium}
                    sx={{ paddingBottom: '0.125rem' }}>
                    {title}
                </Typography>
                <Typography
                    variant='body1'
                    fontSize={fontSizeLegend[size]}
                    fontWeight={typography.fontWeightRegular}>
                    {legend}
                </Typography>
            </Box>
        </Box>
    );
};

export { AvatarGroup };
