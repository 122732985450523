import styled from 'styled-components';

export const Form = styled.form`
    .MuiFormControl-root {
        width: 100%;
        padding: 0.9375rem 1.25rem;
        padding-right: 7.125rem;

        @media (max-width: 900px) {
            padding: 0.625rem 0;
        }

        & > .MuiInputBase-root {
            font-size: 0.875rem;
            & > .MuiOutlinedInput-input {
                padding: 0.625rem 1.25rem;
            }
            
        }
        
        & > .MuiInputBase-multiline {
            font-size: 0.875rem;
            padding: 0;
            & > .MuiInputBase-inputMultiline {
                color: #010101;
            }
        }
    }
`;
