import * as Page from '@pages';

import { RouteObject, Navigate } from 'react-router-dom';

export const stackRoutes: RouteObject[] = [
    {
        path: '/login',
        element: <Page.Login />,
    },
];

export const redirectRoute: RouteObject[] = [
    {
        path: window.location.pathname,
        element: <Navigate to={'/login'} />,
    },
];
