import styled from 'styled-components';

interface ElipseProps {
    matches: boolean;
    color: string;
}

export const Elipse1 = styled.div<ElipseProps>`
    display: block;
    position: absolute;
    width:  ${(props) => props.matches ? '6.875rem' : '9.375rem'};
    height: ${(props) => props.matches ? '105px' : '9.0625rem'};
    background-color: ${(props) => props.color};
    top: 0;
    right: 0;
    border-radius: 0 0.1875rem 0 100%;
    opacity: 0.04;
`;

export const Elipse2 = styled.div<ElipseProps>`
    display: block;
    position: absolute;
    width:  ${(props) => props.matches ? '4.0625rem' : '5.3125rem'};
    height: ${(props) => props.matches ? '3.75rem' : '5rem'};
    background-color:  ${(props) => props.color};;
    top: 0;
    right: 0;
    border-radius: 0 0.1875rem 0 100%;
    opacity: 0.08;
`;
