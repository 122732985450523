import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette } = useTheme();
    const css = {
        sxLink: {
            textDecoration: 'none',
            width: '100%',
        },
        sxButton: {
            'width': '100%',
            'borderRadius': '0',
            'padding': 0,
            'paddingTop': matches ? '1.25rem' : '0.9375rem',
            'paddingBottom': matches ? '1.75rem' : '0.9375rem',
            'display': 'flex',
            'flexDirection': 'column',
            'textTransform': 'none',
            'backgroundColor': palette.lightBlue,
            '&:hover': {
                'backgroundColor': palette.lightBlue,
            },
        },
        sxTitle: {
            fontSize: matches ? '0.875rem' : '0.625rem',
            color: palette.dark,
        },
    };

    return { css };
};
