import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:900px)');
    const { palette, typography } = useTheme();
    const css = {
        sxContainer: {
            'paddingBottom': '1.5625rem',
            '& > :last-child': {
                marginBottom: 0,
            },
        },
        sxBoxPage: {
            backgroundColor: palette.lightBlue,
            height: '100%',
        },
        sxBoxBanner: {
            width: '100%',
            height: '16.25rem',
        },
        sxBoxBannerImg: {
            width: '100%',
            height: '100%',
        },
        sxBoxOverlap: {
            marginTop: '-6.25rem',
            zIndex: '10',
            position: 'relative',
        },
        sxBoxUserData: {
            display: 'flex',
            flexDirection: matches ? 'row' : 'column',
            alignItems: matches ? 'normal' : 'center',
            justifyContent: 'space-between',
        },
        sxDivider: {
            borderColor: palette.clouds,
            width: '100%',
            margin: '0.9375rem 0',
        },
        sxDividerStack: {
            borderColor: palette.clouds,
        },
        sxStackUserBox: {
            width: matches ? 'auto' : '100%',
        },
        sxBalance: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: matches ? 'normal' : 'center',
            padding: matches ? '1.875rem 0.9375rem' : 0,
            justifyContent: 'center',
        },
        sxBalanceTitle: {
            fontSize: matches ? '1.125rem' : '0.875rem',
            fontWeight: typography.fontWeightMedium,
            paddingBottom: '0.375rem',
        },
        sxUpdateText: {
            fontSize: matches ? '0.75rem' : '0.625rem',
            color: palette.midnight,
            fontWeight: typography.fontWeightMedium,
        },
        sxBoxExtract: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: matches ? 'normal' : 'center',
            padding: matches ? '1.875rem 0.9375rem' : 0,
            justifyContent: 'center',
        },
    };
    return { css };
};
