import styled from 'styled-components';

export const SideImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

export const Logo = styled.img`
    height: 100%;
    max-width: 100%;
    object-fit: cover;
`;

export const BgImage = styled.img`
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -1;
    top: -0.3125rem;
    opacity: 0.2;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 24.3125rem;
    
    @media (max-width: 900px) {
        background-color: #fff;
        padding: 1.875rem 0.9375rem;
        border-radius: 0.25rem;
        box-shadow: 0rem 0.1875rem 0.375rem rgba(0,0,0,0.1);
    }
    

    & > :nth-child(2) {
        margin-top: 1.875rem;
        margin-bottom: 1.5625rem;
    }

    & > :nth-child(3) {
        margin-bottom: 0.3125rem;
        
        & > .MuiOutlinedInput-root {
            padding-right: 0;
        }
    }
`;
