import { useTheme } from '@mui/material';

export const sx = () => {
    const { typography } = useTheme();
    const css = {
        sxGridContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        sxLabel: {
            padding: '0.625rem 0',
            fontWeight: typography.fontWeightSemiBold,
            fontSize: '0.875rem',
        },
    };
    return { css };
};
