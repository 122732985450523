import React from 'react';
import {
    Box,
} from '@mui/material';

import { BoxContentProps } from '@interfaces';

import { Image } from './styles';
import { sx } from './sx';

const BoxContent: React.FC<BoxContentProps> = ({
    image, details, children,
}: BoxContentProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxBox }}>
            <Box sx={{ ...css.sxBoxImage }}>
                <Image src={image} alt="" />
            </Box>
            <Box sx={{ ...css.sxBoxContent }}>
                {details}
                {children}
            </Box>
        </Box>
    );
};

export { BoxContent };
