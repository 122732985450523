import React from 'react';

interface FilterCheckProps {
    id?: string;
    name: string;
    children: React.ReactNode;
    onClick: () => void;
}

import { Label } from './styles';

const FilterCheck: React.FC<FilterCheckProps> = ({
    name, children, id, onClick,
}: FilterCheckProps) => {
    return (
        <Label onClick={onClick} htmlFor={id}>
            {name}
            {children}
        </Label>
    );
};

export { FilterCheck };
