import { RouteObject, useRoutes } from 'react-router-dom';

import { authenticatedRoutes } from './authenticated.stack.routes';
import { stackRoutes, redirectRoute } from './stack.routes';

export const Routes = () => {
    const isAuthenticated = (auth: Boolean): RouteObject[] => {
        return auth ? authenticatedRoutes : redirectRoute;
    };

    return useRoutes([...stackRoutes, ...isAuthenticated(true)]);
};
