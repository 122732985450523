import React from 'react';

import {
    Grid,
    Box,
} from '@mui/material';

import {
    Layout,
} from '@components';

import { Topic } from './components';
import { helpData } from '@static/faq';
import { sx } from './sx';

const HelpCenter: React.FC = () => {
    const { css } = sx();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout.SimpleBase
            title='Central de ajuda'
            legend={'Navegue pelas sessões da nossa central de ajuda'}>
            <Box sx={{ ...css.sxBox }}>
                <Grid container spacing={2}>
                    {helpData.map((topic) => (
                        <Grid key={topic.key} item md={4} lg={4} sm={6} xs={12}>
                            <Topic
                                keyValue={topic.key}
                                title={topic.topic}
                                legend={topic.description} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Layout.SimpleBase>
    );
};

export { HelpCenter };
