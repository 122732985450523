export const tabsData = [
    {
        label: 'Ativas',
    },
    {
        label: 'Concluídas',
    },
];

type StatusType = 'active' | 'inactive';

export const campaignData = [
    {
        name: 'Campanha 30mil pontos',
        office: 'Vendedor',
        image: 'https://cdn.discordapp.com/attachments/1044241266134745202/1082652690712113293/itau.png',
        endDate: '05/04/23',
        status: 'active' as StatusType,
        mecanic: 'Volume em R$',
        apuration: 'Pendente',
    },
    {
        name: 'Mac & Spotify',
        office: 'Vendedor',
        image: 'https://cdn.discordapp.com/attachments/1044241266134745202/1082652690439487568/mc.png',
        endDate: '11/02/23',
        status: 'inactive' as StatusType,
        mecanic: 'Volume em R$',
        apuration: 'Pendente',
    },
    {
        name: 'Campanha Tim 5g',
        office: 'Vendedor',
        image: 'https://cdn.discordapp.com/attachments/1044241266134745202/1082652690980544632/tim.png',
        endDate: '05/04/23',
        status: 'active' as StatusType,
        mecanic: 'Volume em R$',
        apuration: 'Pendente',
    },
];
