import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const userMenu = [
    {
        title: 'Meus Dados',
        icon: ['far', 'gear'] as IconProp,
        link: '/meus-dados/perfil',
    },
    {
        title: 'Meu extrato',
        icon: ['far', 'clipboard-list-check'] as IconProp,
        link: '/extrato',
    },
    {
        title: 'Meus pedidos',
        icon: ['far', 'boxes-stacked'] as IconProp,
        link: '/meus-pedidos',
    },
    {
        title: 'Sair',
        color: '#D23B53',
        icon: ['far', 'right-from-bracket'] as IconProp,
    },
];

export const tabsData = [
    {
        label: 'Início',
        path: '/',
    },
    {
        label: 'Meus dados',
        path: '/meus-dados/perfil',
    },
    {
        label: 'Fale conosco',
        path: '/fale-conosco',
    },
    {
        label: 'Hotsite',
        path: '/',
    },
];
