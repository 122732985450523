import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();

    const css = {
        sxStack: {
            border: `0.0625rem solid ${palette.clouds}`,
            borderRadius: '0.25rem',
            width: '8rem',
        },
        sxDivider: {
            borderColor: palette.clouds,
            height: 'auto',
        },
        sxButton: {
            borderRadius: '0',
            width: '2.75rem',
            height: '2.25rem',
        },
        sxIcon: {
            fontSize: '0.875rem',
            color: palette.midnight,
        },
        sxValueBox: {
            width: '2.25rem',
            height: '2.25rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sxNumber: {
            fontWeight: typography.fontWeightSemiBold,
            fontSize: '0.875rem',
        },
    };

    return { css };
};
