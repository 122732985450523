import React from 'react';

import {
    Box,
    Container,
    Grid,
    Stack,
    Divider,
    Table,
    TableBody,
    Typography,
    useTheme,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Layout, Points, ActionButton } from '@components';

import { ProductSlider, TableList } from './components';
import { sliderData } from '@static/product';
import { DeliveryLogo } from './styles';
import { sx } from './sx';

const Product: React.FC = () => {
    const { css } = sx();
    const { palette } = useTheme();
    const navigate = useNavigate();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box sx={{
            backgroundColor: palette.lightBlue,
        }}>
            <Container>
                <Link onClick={() => navigate(-1)}
                    style={{ ...css.sxLinkBack }} to={''}>
                    <I
                        style={{ ...css.sxIconBack }}
                        icon={['far', 'arrow-left-long']} />
                    <Typography
                        sx={{ ...css.sxTextBack }}
                        variant='caption'>
                        VOLTAR
                    </Typography>
                </Link>
            </Container>
            <Layout.SimpleBase noPadding>
                <Box sx={{ ...css.sxBoxGrid }}>
                    <Grid container spacing={2}>
                        <Grid
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{ ...css.sxGridSlider }}
                            item lg={5} sm={12} md={5} xs={12}>
                            <ProductSlider data={sliderData} />
                        </Grid>
                        <Grid item lg={6} sm={12} md={6} xs={12}>
                            <Stack
                                divider={
                                    <Divider
                                        orientation="horizontal"
                                        sx={{ ...css.sxDivider }} />}
                                sx={{ ...css.sxStackInfo }}>
                                <Box sx={{ ...css.sxBoxStack }}>
                                    <Stack spacing={2} sx={{
                                        ...css.sxStackTextInfo,
                                    }}>
                                        <Typography
                                            sx={{ ...css.sxInfoTitle }}
                                            variant='h2'>
                                            Wap
                                        </Typography>
                                        <Typography
                                            sx={{ ...css.sxInfoLegend }}
                                            variant='h3'>
                                            Lavadora de Alta Pressão Wap
                                            Eco Fit 2200 1500psi
                                        </Typography>
                                    </Stack>
                                </Box>
                                <Box sx={{ ...css.sxBoxStack }}>
                                    <Stack
                                        sx={{ ...css.sxStackButtons }}
                                        spacing={1} >
                                        <Points
                                            size={'big'}
                                            value={'1784.00'} />
                                        <ActionButton
                                            size='big'
                                            text={'Comprar agora'}
                                            backgroundColor={'#008732'} />
                                        <ActionButton
                                            size='big'
                                            text={'Adicionar ao carrinho'}
                                            icon={['far', 'cart-shopping']}
                                            backgroundColor={
                                                palette.bluePortal} />
                                    </Stack>
                                </Box>
                                <Box sx={{ ...css.sxBoxStack }}>
                                    <Stack spacing={1}>
                                        <Typography
                                            sx={{ ...css.sxInfoTitle }}
                                            variant='h3'>
                                            Sobre a loja
                                        </Typography>
                                        <Typography
                                            sx={{ ...css.sxDeliveryInfo }}
                                            variant='caption'>
                                            Fornecido e entregue por:
                                        </Typography>
                                        <Box>
                                            <DeliveryLogo src='https://cdn.discordapp.com/attachments/1044241266134745202/1085919593110970429/casas-bahia.png'/>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ ...css.sxBoxStackTable }}>
                    <Stack spacing={2}>
                        <Box>
                            <Typography
                                sx={{ ...css.sxTableTitle }}
                                variant='h4'>
                                Características
                            </Typography>
                            <Table>
                                <TableBody>
                                    <TableList
                                        keyValue={'Características Gerais'}
                                        value={'- Sistema Stop Total: corta o '+
                                        'fluxo de água e a energia do motor'+
                                        'ao soltar o gatilho'} />
                                    <TableList
                                        keyValue={'Uso c/ detergente'}
                                        value={'Não'} />
                                    <TableList
                                        keyValue={'Acessórios'}
                                        value={
                                            '- 1 Pistola de alta pressão com '+
                                        'trava de segurança'} />
                                </TableBody>
                            </Table>
                        </Box>
                        <Box>
                            <Typography
                                sx={{ ...css.sxTableTitle }}
                                variant='h4'>
                                Especificações técnicas
                            </Typography>
                            <Table>
                                <TableBody>
                                    <TableList
                                        keyValue={'Características Gerais'}
                                        value={'- Sistema Stop Total: corta o '+
                                        'fluxo de água e a energia do motor'+
                                        'ao soltar o gatilho'} />
                                    <TableList
                                        keyValue={'Uso c/ detergente'}
                                        value={'Não'} />
                                    <TableList
                                        keyValue={'Acessórios'}
                                        value={
                                            '- 1 Pistola de alta pressão com '+
                                        'trava de segurança'} />
                                </TableBody>
                            </Table>
                        </Box>
                    </Stack>
                </Box>
            </Layout.SimpleBase>
        </Box>
    );
};

export { Product };
