import { useMediaQuery } from '@mui/material';

export const sx = ({
    noPadding,
}: {noPadding?: boolean}) => {
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            backgroundColor: '#FBFEFF',
        },
        sxContainer: {
            padding: matches ? noPadding ? '0 1.5rem' : '1.5rem' :
                noPadding ? '0 1rem' : '1rem',
        },
        sxBoxChildren: {
        },
    };

    return { css };
};
