import { Box, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';

interface HeaderPageProps {
    title: string;
    onBack?: () => void;
}

const HeaderPage: React.FC<HeaderPageProps> = ({
    title, onBack,
}: HeaderPageProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxBox }}>
            {onBack &&
                <IconButton
                    onClick={onBack}
                    sx={{ ...css.sxIconButton }}>
                    <I icon={['far', 'chevron-left']} />
                </IconButton>
            }
            <Typography
                sx={{ ...css.sxTitle }}
                variant='h1'>
                {title}
            </Typography>
        </Box>
    );
};

export { HeaderPage };

