import React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { HistoryItemProps } from '@interfaces';
import { sx } from './sx';

const HistoryItem: React.FC<HistoryItemProps> = ({
    points, dateInfo, place, endItem, expire,
}: HistoryItemProps) => {
    const { css } = sx({
        points: points,
        expire: expire,
    });

    const handleIcon = {
        positive: ['fas', 'check'],
        negative: ['far', 'circle-minus'],
    };

    return (
        <Box sx={{ ...css.sxBoxContainer }}>
            <Box>
                {!endItem &&
                    <Divider
                        orientation="vertical" sx={{ ...css.sxDivider }}/>
                }
            </Box>
            <Stack
                width={'100%'}
                justifyContent={'space-between'}
                direction={'row'}>
                <Box sx={{ ...css.sxBoxInfo }}>
                    <I
                        style={{ ...css.sxIcon }}
                        icon={ expire ? ['far', 'calendar-clock'] : handleIcon[
                            points > 0 ? 'positive' : 'negative'
                        ] as any} />
                    <Box>
                        <Typography sx={{ ...css.sxDate }} variant='h4'>
                            Creditado em {dateInfo}
                        </Typography>
                        <Typography sx={{ ...css.sxPlace }} variant='body1'>
                            {place}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ ...css.sxPoints }} variant='h4'>
                        {points} pontos
                    </Typography>
                </Box>
            </Stack>
        </Box>
    );
};

export { HistoryItem };
