import styled from 'styled-components';

export const Label = styled.label`
    padding: 0.1875rem 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins';
    font-size: 0.8125rem;
    cursor: pointer;
`;
