import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { AvatarGroup, MenuItem } from '@components';
import { sx } from './sx';
import { useNavigate } from 'react-router-dom';
import { userMenu } from '@static/menus';

const UserMenu: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();
    return (
        <Box sx={{ ...css.sxBoxPage }}>
            <Box sx={{ ...css.sxBoxButton }}>
                <IconButton
                    onClick={() => navigate(-1)}
                    sx={{ ...css.sxBackButton }}>
                    <I icon={['far', 'arrow-right-long']} />
                </IconButton>
            </Box>
            <AvatarGroup
                align={'bottom'}
                size={'small'}
                src={''} />
            <Typography
                variant='h2'
                sx={{ ...css.sxUsername }}>
                Amanda Monteiro
            </Typography>
            <Typography
                variant='h1'
                sx={{ ...css.sxMenuTitle }}>
                Menu
            </Typography>
            <Stack>
                {userMenu.map(({
                    icon, title, color, link }, i) => (
                    <MenuItem
                        key={`${title}-${i}`}
                        icon={icon}
                        mode={'menuIcon'}
                        borderType={'bottom'}
                        color={color}
                        title={title}
                        onClick={() => navigate(link ? link : '')}>
                        <I
                            style={{ fontSize: '1rem' }}
                            icon={
                                ['far', 'chevron-right']}
                        />
                    </MenuItem>
                ))}
            </Stack>
        </Box>
    );
};

export { UserMenu };
