import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { StoreItem, Points } from '@components';
import { ProductGridProps } from '@interfaces';

import { PartnerLogo } from './styles';
import { sx } from './sx';

const ProductGrid: React.FC<ProductGridProps> = ({
    link, image, description, points, partnerLogo,
}: ProductGridProps) => {
    const { css } = sx();
    return (
        <StoreItem product link={link} image={image}>
            <Box>
                <Typography
                    sx={{ ...css.sxDescription }}
                    variant='caption'>
                    {description}
                </Typography>
                <Stack
                    sx={{ ...css.sxStackInfo }}
                    spacing={1}>
                    <PartnerLogo src={partnerLogo} />
                    <Points size={'medium'} value={points} />
                </Stack>
            </Box>
        </StoreItem>
    );
};

export { ProductGrid };
