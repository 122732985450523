import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const css = {
        sxBoxIcon: {
            height: '2.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        sxBox: {
            padding: matches ? '1.875rem' : '0.9375rem',
            paddingTop: matches ? '1.875rem' : '0',
        },
        sxTabs: {
            'width': '100%',
            'height': '100%',
            'marginBottom': '0.9375rem',
            'padding': matches ? '0 1.875rem' : '0 0.9375rem',
            'borderBottom': `0.0625rem solid ${palette.clouds}`,
            '& > .MuiTabs-scroller': {
                '& > .MuiTabs-indicator': {
                    'height': '0.1875rem',
                    'backgroundColor': palette.bluePortal,
                },
                '& > .MuiTabs-flexContainer': {
                    'height': '100%',
                    '& > .MuiTab-root': {
                        'padding': '1.0625rem',
                        'width': '50%',
                        'fontSize': '1rem',
                        'fontWeight': typography.fontWeightMedium,
                        'textTransform': 'none',
                        '&.Mui-selected': {
                            color: palette.bluePortal,
                        },
                    },
                },
            },
        },
    };
    return { css };
};
