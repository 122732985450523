export const sliderData = [
    {
        image: 'https://cdn.discordapp.com/attachments/1044241266134745202/1085891256829349929/wap.png',
    },
    {
        image: 'https://cdn.discordapp.com/attachments/1044241266134745202/1085891256829349929/wap.png',
    },
    {
        image: 'https://cdn.discordapp.com/attachments/1044241266134745202/1085891256829349929/wap.png',
    },
    {
        image: 'https://cdn.discordapp.com/attachments/1044241266134745202/1085891256829349929/wap.png',
    },
];
