import styled from 'styled-components';

export const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: table-caption !important;
`;
