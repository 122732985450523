import styled from 'styled-components';

export const Icon = styled.img`
    height: 100%;
    width: 100%;
    max-height: 4rem;
    max-width: 4.125rem;
    margin-bottom: 0.5rem;

    @media (max-width: 900px) {
        margin-bottom: 0;
        max-height: 2.75rem;
        max-width: 2.875rem;
    }
`;
