import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxSeeProductText: {
            fontSize: matches ? '0.8125rem' : '0.625rem',
            fontWeight: typography.fontWeightLight,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            color: '#6A6F7A',
            paddingBottom: matches ? '0.9375rem' : '0',
        },
        sxIcon: {
            fontSize: matches ? '0.875rem' : '0.75rem',
            paddingLeft: '0.5rem',
        },
    };

    return { css };
};
