import React from 'react';

import {
    Typography, Grid,
} from '@mui/material';

import { Label } from './styles';
import { sx } from './sx';
import { FormItemProps } from '@interfaces';

const FormItem: React.FC<FormItemProps> = ({
    label, htmlFor, children,
}: FormItemProps) => {
    const { css } = sx();
    return (
        <Grid container sx={{
            ...css.sxGridContainer,
        }}>
            <Grid item lg={4} md={4} sm={4} xs={12}>
                <Label htmlFor={htmlFor}>
                    <Typography
                        sx={{ ...css.sxLabel }}
                        variant='caption'>
                        {label}
                    </Typography>
                </Label>
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={12}>
                {children}
            </Grid>
        </Grid>
    );
};

export { FormItem };
