import React from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { StatusProps } from '@interfaces';

import { sx } from './sx';

const Status: React.FC<StatusProps> = ({
    statusType, date, endItem,
}: StatusProps) => {
    const { css } = sx();
    const handleStatus = {
        awaitingPayment: {
            icon: ['fas', 'hourglass-clock'],
            message: 'Aguardando Pagamento',
        },
        paid: {
            icon: ['fas', 'check'],
            message: 'Pago',
        },
        awaitingSend: {
            icon: ['fas', 'clock'],
            message: 'Aguardando Envio',
        },
    };

    return (
        <Box>
            {!endItem &&
                <Divider
                    orientation="vertical" sx={{ ...css.sxDivider }}/>
            }
            <Box sx={{ ...css.sxBox }}>
                <I
                    style={{ ...css.sxIcon }}
                    icon={handleStatus[statusType].icon as any} />
                <Box sx={{ ...css.sxBoxInfo }}>
                    <Typography
                        sx={{ ...css.sxMessage }}
                        variant='caption'>
                        {handleStatus[statusType].message}
                    </Typography>
                    <Typography
                        sx={{ ...css.sxDate }}
                        variant='body1'>
                        {date}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export { Status };
