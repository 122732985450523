import React from 'react';

import {
    useParams,
    Link,
} from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    Grid,
    Typography,
    Stack,
    Box,
    IconButton,
    Divider,
} from '@mui/material';

import {
    Layout,
} from '@components';

import { helpData } from '@static/faq';
import { sx } from './sx';

interface AnswerProps {
    question: string;
    answer: string;
}

const QuestionsAnswers: React.FC = () => {
    const { topic } = useParams();
    const data = helpData.find((obj) => obj.key === topic) || helpData[0];
    const { css } = sx();
    const [answer, setAnswer] = React.useState<AnswerProps>(
        data.questions[0],
    );
    const [mode, setMode] = React.useState<'question' | 'answer'>('question');

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout.SimpleBase>
            <Box sx={{ ...css.sxBoxContainer }}>
                <Link
                    onClick={() => {
                        mode === 'question' ?
                            null : setMode('question');
                    }}
                    to={mode === 'question' ? '/central-de-ajuda' : ''}
                    style={{ ...css.sxLink }}>
                    <Typography
                        sx={{ ...css.sxTextLink }} variant='caption'>
                        <IconButton sx={{ ...css.sxIconLinkBack }}>
                            <I icon={['far', 'arrow-left-long']} />
                        </IconButton>
                            Voltar para
                        { mode === 'question' ? ' a Central de Ajuda' :
                            ` ${data.topic}` }
                    </Typography>
                </Link>
                <Box sx={{
                    display: 'flex',
                    marginTop: '0.625rem',
                }}>
                    <Grid container spacing={2}>
                        <Grid item md={8} lg={8} sm={12} xs={12}>
                            <Typography sx={{ ...css.sxTitle }} variant='h2'>
                                {mode === 'question' ?
                                    data.topic : answer.question}
                            </Typography>
                            {mode === 'question' ?
                                <Stack
                                    divider={
                                        <Divider
                                            flexItem
                                            sx={{ ...css.sxDivider }}
                                            orientation="horizontal" />}>
                                    {data.questions.map((question) => (
                                        <Box
                                            key={question.question}
                                            sx={{ ...css.sxBoxQuestion }}
                                            onClick={() => {
                                                setAnswer(question);
                                                setMode('answer');
                                            }}>
                                            <Typography
                                                sx={{ ...css.sxQuestionText }}
                                                variant='h3'>
                                                {question.question}
                                            </Typography>
                                            <I
                                                icon={[
                                                    'far', 'chevron-right']} />
                                        </Box>
                                    ))}
                                </Stack> :
                                <Box
                                    sx={{ ...css.sxBoxAnswer }}
                                    onClick={() => {
                                        setMode('question');
                                    }}>
                                    <Typography
                                        sx={{ ...css.sxAnswerText }}
                                        variant='body1'>
                                        {answer.answer}
                                    </Typography>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Layout.SimpleBase>
    );
};

export { QuestionsAnswers };
