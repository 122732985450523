import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const css = {
        sxStack: {
            padding: matches ? '1rem' : '0',
        },
        sxDivider: {
            borderColor: palette.clouds,
        },
        sxStatusIcon: {
            marginRight: '0.25rem',
            fontSize: '0.625rem',
        },
    };

    return { css };
};
