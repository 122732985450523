import { useTheme, useMediaQuery } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const css = {
        sxLink: {
            textDecoration: 'none',
            color: palette.dark,
        },
        sxBox: {
            'border': `0.0625rem solid ${palette.clouds}`,
            'padding': matches ? '0.9375rem 1.875rem' : '0.9375rem',
            'cursor': 'pointer',
            '&:hover': {
                'span': {
                    textDecoration: 'underline',
                },
            },
        },
        sxTitle: {
            fontWeight: typography.fontWeightSemiBold,
            fontSize: '1.125rem',
            padding: '0.5625rem 0',
        },
        sxLegend: {
            fontWeight: typography.fontWeightLight,
            fontSize: '0.875rem',
            paddingBottom: '0.3125rem',
        },
        sxLinkText: {
            color: palette.bluePortal,
        },
    };
    return { css };
};
