import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:900px)');
    const { palette } = useTheme();
    const css = {
        sxStack: {
            display: matches ? 'none' : 'flex',
            backgroundColor: palette.common.white,
            height: '3.125rem',
            bottom: '0',
            left: '0',
            position: 'fixed',
            width: '100%',
            boxShadow: '0rem -0.125rem 0.625rem rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
            padding: '0 1.625rem',
        },
        sxIconButton: {
            'fontSize': '1.25rem',
            'color': palette.dark,
            'flexDirection': 'column',
            'alignItems': 'center',
            '&.active': {
                color: palette.bluePortal,
            },
        },
        sxTitle: {
            fontSize: '0.625rem',
        },
        sxBadge: {
            '& > .MuiBadge-badge': {
                transform: 'scale(1) translate(-50%, 20%)',
                fontSize: '0.625rem',
                height: '1.125rem',
                minWidth: '1.125rem',
                backgroundColor: '#6A6F7A',
                color: palette.common.white,
            },
        },
    };

    return { css };
};
