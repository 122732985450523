import { useMediaQuery, useTheme } from '@mui/material/';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const css = {
        sxOverlay: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,0.4)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10500,
        },
        sxRoot: {
            'backgroundColor': palette.common.white,
            'maxWidth': '36.875rem',
            'width': '100%',
            'height': 'auto',
            'boxShadow': '0 0.1875rem 0.375rem rgba(0,0,0,0.4)',
            'borderRadius': '0.25rem',
            'position': 'relative',
            'zIndex': 2800,

            '@media (max-width: 900px)': {
                margin: '0.9375rem',
            },
            '&.all-screen': {
                'maxWidth': '100%',
                'height': '100%',
                'margin': '0',
            },
        },
        sxHeader: {
            'backgroundColor': palette.common.white,
            'width': '100%',
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'borderRadius': '0.25rem 0.25rem 0 0',
            'zIndex': 2500,
            'padding': '0.625rem 0.9375rem',
            'borderBottom': `0.0625rem solid ${palette.clouds}`,
        },
        sxTitle: {
            fontSize: matches ? '1.25rem' : '1rem',
            fontWeight: typography.fontWeightBold,
        },
        sxIconButton: {
            height: '2.5rem',
            width: '2.5rem',
            borderRadius: 0,
        },
        sxIconClose: {
            fontSize: '1.25rem',
            opacity: 0.5,
            color: palette.dark,
        },
    };

    return { css };
};
