import React from 'react';
import {
    Container,
    Grid,
    Box,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { MenuItem } from '@components';

import { sx } from './sx';
import { dashboardMenu } from '@static/dashboard';

const DashboardBase = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const location = useLocation();
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { css } = sx();

    return (
        <Box sx={{ ...css.sxBox }}>
            <Container maxWidth="lg">
                <Box sx={{ ...css.sxContainerBox }}>
                    <Grid container spacing={2}>
                        {matches && (
                            <Grid item xs={3} sm={4} md={3}>
                                <Box
                                    sx={{ ...css.sxBoxMenu }}>
                                    {dashboardMenu.map(({
                                        icon, title, path, pathNav }, i) => (
                                        <MenuItem
                                            onClick={() =>
                                                navigate(pathNav || path)}
                                            key={`${title}-${i}`}
                                            icon={icon}
                                            mode={'dashboard'}
                                            borderType={'bottom'}
                                            title={title}
                                            color={
                                                location.pathname
                                                    .includes(path) ?
                                                    palette.bluePortal : ''
                                            } />
                                    ))}
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={matches ? 9 : 12} sm={8} md={9}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export { DashboardBase };
