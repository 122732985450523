import React from 'react';
import { Badge, IconButton, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useLocation, useNavigate } from 'react-router-dom';
import { fixedMenuData } from '@static/fixed-menu';

const FixedMenu: React.FC = () => {
    const { css } = sx();
    const location = useLocation();
    const navigate = useNavigate();

    const handleActive = (path: string) => {
        if (location.pathname !== '/') {
            if (path.match(location.pathname.replace('/', ''))) {
                return 'active';
            }
        } else if (path === '') {
            return 'active';
        }
        return 'inactive';
    };

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ ...css.sxStack }}>
            {fixedMenuData.map((menu) => (
                <Badge
                    key={menu.id}
                    sx={{ ...css.sxBadge }}
                    badgeContent={menu.id === 'carrinho' ? 3 : 0}>
                    <IconButton
                        onClick={() => navigate(menu.link)}
                        className={handleActive(menu.path) === 'active' ?
                            'active' : ''}
                        sx={{
                            ...css.sxIconButton,
                        }}>
                        <I icon={[
                            handleActive(menu.path) === 'active' ?
                                'fas' : 'fal',
                            menu.icon as IconName]} />
                        <Typography
                            sx={{ ...css.sxTitle }}
                            variant='caption'>
                            {menu.label}
                        </Typography>
                    </IconButton>
                </Badge>

            ))}
        </ Stack>
    );
};

export { FixedMenu };
