import { useMediaQuery, useTheme } from '@mui/material';

export const sx = ({
    product,
}: {product?: boolean}) => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxLink: {
            border: `0.0625rem solid ${palette.clouds}`,
            borderRadius: '0.1875rem',
            display: 'flex',
            width: '100%',
            maxWidth: matches ? '17rem' : product ? '100%' : '10.9375rem',
            textDecoration: 'none',
        },
        sxBoxImage: {
            height: matches ? '12.5rem' : '7.5625rem',
            minHeight: '8.125rem',
            width: '100%',
        },
        sxBoxChildren: {
            padding: '0.9375rem',
        },
    };

    return { css };
};
