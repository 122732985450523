import { useTheme, useMediaQuery } from '@mui/material';

export const sx = () => {
    const { typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            display: 'flex',
            flexDirection: 'column',
            padding: matches ? '1.875rem 0' : '0.9375rem 0',
        },
        sxBoxTitle: {
            padding: matches ? '0 1.875rem' : '0 0.9375rem',
            paddingBottom: matches ? '1.25rem' : '0.9375rem',
        },
        sxTitle: {
            fontWeight: typography.fontWeightMedium,
            fontSize: matches ? '1.625rem' : '1.375rem',
            marginBottom: '0.375rem',
        },
        sxLegend: {
            fontWeight: typography.fontWeightLight,
            fontSize: matches ? '0.875rem' : '0.75rem',
        },
    };
    return { css };
};
