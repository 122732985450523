import { RouteObject } from 'react-router-dom';
import * as Page from '@pages';
import { Profile } from '@pages';

import { Layout } from '@components';

export const authenticatedRoutes: RouteObject[] = [
    {
        element: <Layout.DashboardBase />,
        children: [
            {
                element: <Profile.Base />,
                children: [
                    {
                        path: '/meus-dados',
                        element: <Profile.MyData />,
                    },
                    {
                        path: '/meus-dados/perfil',
                        element: <Profile.MyData />,
                    },
                    {
                        path: '/meus-dados/senha',
                        element: <Profile.Password />,
                    },
                ],
            },
            {
                path: '/extrato',
                element: <Page.Extract />,
            },
            {
                path: '/meus-pedidos',
                element: <Page.Orders />,
            },
        ],
    },
    {
        path: '/campanhas',
        element: <Page.Campaigns />,
    },
    {
        path: '/fale-conosco',
        element: <Page.ContactUs />,
    },
    {
        path: '/central-de-ajuda',
        element: <Page.HelpCenter />,
    },
    {
        path: '/central-de-ajuda/:topic',
        element: <Page.QuestionsAnswers />,
    },
    {
        path: '/produto',
        element: <Page.Product />,
    },
    {
        path: '/carrinho',
        element: <Page.Cart />,
    },
    {
        path: '/loja',
        element: <Page.Store />,
    },
    {
        path: '/resultados',
        element: <Page.Results />,
    },
    {
        path: '/user-menu',
        element: <Page.UserMenu />,
    },
    {
        path: '/menu',
        element: <Page.MenuDefault />,
    },
    {
        path: '/',
        element: <Page.Home />,
    },
];
