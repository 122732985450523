export const fixedMenuData = [
    {
        id: 'inicio',
        label: 'Início',
        icon: 'home',
        path: '',
        link: '',
    },
    {
        id: 'extrato',
        label: 'Extrato',
        icon: 'clipboard-list-check',
        path: 'extrato',
        link: 'extrato',
    },
    {
        id: 'catalogo',
        label: 'Catálogo',
        icon: 'megaphone',
        path: 'loja resultados',
        link: 'loja',
    },
    {
        id: 'carrinho',
        label: 'Carrinho',
        icon: 'cart-shopping',
        path: 'carrinho',
        link: 'carrinho',
    },
];
