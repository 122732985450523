import React from 'react';

import {
    Box,
    Button,
    Checkbox,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Layout, ProductGrid, HeaderPage } from '@components';
import { filtersData, orderData } from '@static/filters';

import { SearchBar } from '../components/search-bar';
import { FilterSection } from './components/filter-section';
import { FilterCheck } from './components/filter-checkbox';
import { ButtonSelectedFilter } from './components/selected-filter';

import { sx } from './sx';
import { BoxActiveFiltersList } from './styles';
import { ButtonResetFilters } from './components/reset-filters';

const Results: React.FC = () => {
    const { css } = sx();
    const matches = useMediaQuery('(min-width:900px)');
    const [showFilters, setShowFilters] = React.useState(false);
    const [showOrder, setShowOrder] = React.useState(false);

    const handleOverflow = () => {
        const body = document.querySelector('body');
        if (showFilters || showOrder) {
            body?.classList.add('overflow-hidden');
        } else {
            body?.classList.remove('overflow-hidden');
        }
    };

    React.useEffect(() => {
        handleOverflow();
    }, [showFilters, showOrder]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout.SimpleBase>
            <SearchBar />
            <Box sx={{ ...css.sxBox }}>
                <Box sx={{ ...css.sxBoxTitle }}>
                    <Typography
                        sx={{ ...css.sxTitle }}
                        variant={'h2'}>
                        Uma seleção de ofertas especiais para você
                    </Typography>
                    <Typography
                        sx={{ ...css.sxLegend }}
                        variant={'body1'}>
                        Veja as principais ofertas de nossos parceiros
                    </Typography>
                </Box>
                {!matches &&
                <>
                    <Stack
                        sx={{ padding: '0.4375rem 0' }}
                        spacing={1.5}
                        direction={'row'}>
                        <Button
                            onClick={() => setShowOrder(true)}
                            sx={{ ...css.sxButtonFiltrage }}>
                            Ordenar por
                            <I icon={['fas', 'arrow-up-arrow-down']} />
                        </Button>
                        <Button
                            onClick={() => setShowFilters(true)}
                            sx={{ ...css.sxButtonFiltrage }}>
                            Filtros
                            <I icon={['fas', 'sliders']} />
                        </Button>
                    </Stack>
                    <Typography
                        sx={{ ...css.sxFilterTitle }}
                        variant='h2'>
                        Filtros
                    </Typography>
                    <Box
                        display='flex'
                        alignItems='center'>
                        <Typography
                            variant='h2'
                            sx={{ ...css.sxActiveFiltersText }}>
                                        Filtros<br />
                                        Ativos:
                        </Typography>
                        <BoxActiveFiltersList>
                            <ButtonSelectedFilter
                                title='tamanho'
                                value='37, 38' />
                            <ButtonResetFilters
                                text='Limpar filtros'
                            />
                        </BoxActiveFiltersList>
                    </Box>
                </>
                }
                {!matches && showOrder &&
                    <Box
                        sx={{ ...css.sxContainerFilters }}
                        className={`${showOrder ? 'active' : ''} mobile`}>
                        <HeaderPage
                            onBack={() => setShowOrder(false)}
                            title={'Ordenar'} />
                        <Box sx={{ ...css.sxBoxFilters }}>

                            {orderData.map((filter) => (
                                <FilterSection
                                    key={filter.id}
                                    filterName={filter.filterName}
                                    buttonAction={() => null}>
                                    <Box
                                        display='flex'
                                        alignItems='center'>
                                        <BoxActiveFiltersList>
                                            <ButtonSelectedFilter
                                                title={filter.filterName}
                                                value='37, 38' />
                                        </BoxActiveFiltersList>
                                    </Box>
                                    {filter.filtersList
                                        .map((filterList) => (
                                            <FilterCheck
                                                key={filterList.id}
                                                id={filterList.id}
                                                name={filterList.name}
                                                onClick={() => null}>
                                                <input
                                                    type={'radio'}
                                                    id={filterList.id}
                                                    name={filter.filterName}
                                                    value={
                                                        filterList.name} />
                                            </FilterCheck>
                                        ))}
                                </FilterSection>
                            ))}
                        </Box>
                    </Box>
                }
                <Grid container spacing={2}>
                    <Grid
                        sx={{
                            paddingTop: matches ? '1rem' : '0 !important',
                        }}
                        item lg={3} md={3} sm={12} xs={12}>
                        <Box
                            sx={{ ...css.sxContainerFilters }}
                            className={matches ? '' :
                                `${showFilters ? 'active' : ''} mobile`}>
                            {!matches &&
                                <HeaderPage
                                    onBack={() => setShowFilters(false)}
                                    title={'Filtros'} />
                            }
                            {matches &&
                                <Box
                                    sx={{ padding: matches ? 0 : '0 0.875rem' }}
                                    display='flex'
                                    alignItems='center'>
                                    <Typography
                                        variant='h2'
                                        sx={{ ...css.sxActiveFiltersText }}>
                                            Filtros<br />
                                            Ativos:
                                    </Typography>
                                    <BoxActiveFiltersList>
                                        <ButtonSelectedFilter
                                            title='tamanho'
                                            value='37, 38' />
                                        <ButtonResetFilters
                                            text='Limpar filtros'
                                        />
                                    </BoxActiveFiltersList>
                                </Box>
                            }
                            <Box sx={{ ...css.sxBoxFilters }}>
                                {filtersData.map((filter) => (
                                    <FilterSection
                                        key={filter.id}
                                        filterName={filter.filterName}
                                        buttonAction={() => null}>
                                        <Box
                                            display='flex'
                                            alignItems='center'>
                                            <BoxActiveFiltersList>
                                                <ButtonSelectedFilter
                                                    title={filter.filterName}
                                                    value='37, 38' />
                                            </BoxActiveFiltersList>
                                        </Box>
                                        {filter.filtersList
                                            .map((filterList) => (
                                                <FilterCheck
                                                    key={filterList.id}
                                                    id={filterList.id}
                                                    name={filterList.name}
                                                    onClick={() => null}>
                                                    <Checkbox
                                                        sx={{
                                                            ...css
                                                                .sxCheckbox,
                                                        }}
                                                        id={filterList.id}
                                                        value={
                                                            filterList.name}
                                                        size='small' />
                                                </FilterCheck>
                                            ))}
                                    </FilterSection>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                                <Grid key={`product-${i}`}
                                    item lg={4} md={4} sm={4} xs={6}>
                                    <ProductGrid
                                        link={'/produto'}
                                        image={'https://cdn.discordapp.com/attachments/1044241266134745202/1085891256829349929/wap.png'}
                                        description={
                                        // eslint-disable-next-line max-len
                                            'Lavadora de Alta Pressão Wap Eco Fit 2200 1500psi ededwedwed 977giuguiui'}
                                        points={'371.00'}
                                        partnerLogo={'https://cdn.discordapp.com/attachments/1044241266134745202/1082261363788877845/portal-logo.png'} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Layout.SimpleBase>
    );
};

export { Results };
