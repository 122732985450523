import React from 'react';

import {
    Box,
    Tabs,
    Tab,
    useTheme,
    Typography,
    Divider,
    Stack,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import { Layout } from '@components';

import { PeriodButton } from './components/period-button';
import { HistoryItem } from './components/history-item';

import { sx } from './sx';
import { tabsData, periodsData } from '@static/extract';
import { ExtractHistory, List } from './styles';

const Extract: React.FC = () => {
    const { palette } = useTheme();
    const [valueTab, setValueTab] = React.useState('0');
    const { css } = sx({
        clientColor: palette.bluePortal,
    });

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Layout.AuthenticatedContent title='Extrato'>
                <Box sx={{ ...css.sxBoxBalance }}>
                    <Typography sx={{ ...css.sxTitleBalance }} variant='h3'>
                        Saldo
                    </Typography>
                    <Box sx={{ ...css.sxBoxInfoBalance }}>
                        <Typography
                            sx={{ ...css.sxTitleInfoBalance }}
                            variant='h4'>
                            Disponível
                        </Typography>
                        <Typography
                            sx={{ ...css.sxPointsBalance }}
                            variant='body1'>
                            486 pontos
                        </Typography>
                    </Box>
                    <Divider
                        sx={{ ...css.sxDividerBalance }}
                        orientation='horizontal' />
                    <Box sx={{ ...css.sxBoxInfoBalance }}>
                        <Typography
                            sx={{ ...css.sxTitleInfoBalance }}
                            variant='h4'>
                            Total
                        </Typography>
                        <Typography
                            sx={{ ...css.sxPointsBalance }}
                            variant='body1'>
                            486 pontos
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ ...css.sxBoxInfoUpdate }}>
                    <Typography variant='caption' sx={{
                        ...css.sxInfoUpdate,
                    }}>
                        Informações capturadas em 24/02/2023 às 17:00 e
                        sujeitas a alterações até o fim do dia.
                    </Typography>
                </Box>
            </Layout.AuthenticatedContent>
            <Layout.AuthenticatedContent>
                <TabContext value={valueTab}>
                    <Box>
                        <Tabs
                            value={valueTab}
                            aria-label="basic tabs example"
                            sx={{ ...css.sxTabs }}>
                            {tabsData.map(({ label }, i) => (
                                <Tab
                                    value={String(i)}
                                    key={label}
                                    label={label}
                                    onClick={() => setValueTab(String(i))}/>
                            ))}
                        </Tabs>
                    </Box>
                    <Box sx={{ paddingTop: '0.625rem' }}>
                        <Typography variant='caption' sx={{
                            ...css.sxPeriodsSelect,
                        }}>
                            Selecione um período
                        </Typography>
                        <Stack
                            sx={{ ...css.sxStackPeriods }}
                            direction={'row'}
                            spacing={1}>
                            {periodsData.map((period) => (
                                <PeriodButton
                                    key={period.value}
                                    id={period.value}
                                    period={period.label}>
                                    <input
                                        id={period.value}
                                        value={period.value}
                                        name='period'
                                        type='radio' />
                                </PeriodButton>

                            ))}
                        </Stack>
                        <TabPanel value={'0'}>
                            <Box sx={{ ...css.sxBoxHistory }}>
                                <ExtractHistory>
                                    <List>
                                        <HistoryItem
                                            endItem
                                            points={4}
                                            dateInfo={'03/02/2023 - 15:12'}
                                            place={'Pontuação Shopping'} />
                                    </List>
                                    <List>
                                        <HistoryItem
                                            points={9}
                                            dateInfo={'02/02/2023 - 17:45'}
                                            place={'Pontos em dobro'} />
                                    </List>
                                    <List>
                                        <HistoryItem
                                            points={-1000}
                                            dateInfo={'02/02/2023 - 17:45'}
                                            place={'Pontos em dobro'} />
                                    </List>
                                </ExtractHistory>
                            </Box>
                        </TabPanel>
                        <TabPanel value={'1'}>
                            <Box sx={{ ...css.sxBoxHistory }}>
                                <ExtractHistory>
                                    <List>
                                        <HistoryItem
                                            expire
                                            endItem
                                            points={4}
                                            dateInfo={'03/02/2023 - 15:12'}
                                            place={'Pontuação Shopping'} />
                                    </List>
                                    <List>
                                        <HistoryItem
                                            expire
                                            points={9}
                                            dateInfo={'02/02/2023 - 17:45'}
                                            place={'Pontos em dobro'} />
                                    </List>
                                    <List>
                                        <HistoryItem
                                            expire
                                            points={1000}
                                            dateInfo={'02/02/2023 - 17:45'}
                                            place={'Pontos em dobro'} />
                                    </List>
                                </ExtractHistory>
                            </Box>
                        </TabPanel>
                    </Box>

                </TabContext>
            </Layout.AuthenticatedContent>
        </>
    );
};

export { Extract };
