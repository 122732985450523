import { useTheme } from '@mui/material';


export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBoxPage: {
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            backgroundColor: palette.common.white,
            zIndex: 9999,
        },
        sxBoxButton: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0.625rem',
        },
        sxBackButton: {
            'padding': '0.625rem',
            'backgroundColor': '#F9F9F9',
            'borderRadius': 0,
            'color': palette.dark,
            'fontSize': '1.25rem',
            '&:hover': {
                'backgroundColor': '#F9F9F9 !important',
            },
            '& > .MuiTouchRipple-root': {
                display: 'none',
            },
        },
        sxUsername: {
            fontWeight: 600,
            fontSize: '0.875rem',
            padding: '0.625rem 0.9375rem',
            textAlign: 'center',
        },
        sxMenuTitle: {
            textTransform: 'uppercase',
            fontWeight: typography.fontWeightMedium || 500,
            fontSize: '0.625rem',
            padding: '0.625rem 0.9375rem',
        },
    };

    return { css };
};
