import React from 'react';
import {
    Box,
    TextField,
    MenuItem,
    Stack,
    Divider,
    useMediaQuery,
} from '@mui/material';

import {
    FormItem,
    AvatarGroup,
    ActionButton,
} from '@components';
import { Form, FakeInput } from '../styles';
import { sx } from './sx';

const status = [
    {
        value: 'Solteiro (a)',
    },
    {
        value: 'Casado (a)',
    },
];

const genres = [
    {
        value: 'Homem',
    },
    {
        value: 'Mulher',
    },
    {
        value: 'Prefiro não informar',
    },
];

const MyData: React.FC = () => {
    const matches = useMediaQuery('(min-width:900px)');
    const { css } = sx();
    return (
        <Box>
            <Form>
                <Stack
                    divider={ matches ? <Divider
                        sx={{ ...css.sxDivider }}
                        orientation="horizontal"
                        flexItem /> : <></>}
                    spacing={1}>
                    <FormItem label={'Cargo'}>
                        <Box sx={{ ...css.sxBoxFakeInput }}>
                            <FakeInput>
                                Portal Administrador
                            </FakeInput>
                        </Box>
                    </FormItem>
                    <FormItem label={'Nome Completo'}>
                        <Box sx={{ ...css.sxBoxFakeInput }}>
                            <FakeInput>
                                Amanda Monteiro
                            </FakeInput>
                        </Box>
                    </FormItem>
                    <FormItem label={'Foto de perfil'}>
                        <Box sx={{ ...css.sxBoxPicture }}>
                            <AvatarGroup
                                borderWidth='none'
                                align={'bottom'}
                                size={'medium'}
                                src={''} />

                            <ActionButton
                                size='small'
                                text={'Alterar'}
                                backgroundColor={'#6115DD'}
                                icon={['fas', 'camera']} />
                        </Box>
                    </FormItem>
                    <FormItem htmlFor='civil' label={'Estado Civil'}>
                        <TextField
                            select
                            defaultValue={status[0].value}
                            id='civil'>
                            {status.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    sx={{ ...css.sxMenuItem }}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormItem>
                    <FormItem htmlFor='email' label={'E-mail'}>
                        <TextField
                            id='email'
                            defaultValue={'amanda@gmail.com'}/>
                    </FormItem>
                    <FormItem htmlFor='cellphone' label={'Telefone'}>
                        <TextField
                            id='cellphone'
                            defaultValue={'(42) 95544 - 5445'}/>
                    </FormItem>
                    <FormItem htmlFor='genre' label={'Gênero'}>
                        <TextField
                            select
                            defaultValue={genres[1].value}
                            id='genre'>
                            {genres.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    sx={{ ...css.sxMenuItem }}>
                                    {option.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormItem>
                    <FormItem htmlFor='born-date' label={'Data de nascimento'}>
                        <TextField
                            id='born-date'
                            defaultValue={'19/12/1991'}/>
                    </FormItem>
                    <Box sx={{ ...css.sxBoxButton }}>
                        <ActionButton
                            size={'medium'}
                            text={'Salvar alterações'}
                            backgroundColor={'#6115DD'} />
                    </Box>
                </Stack>
            </Form>
        </ Box>
    );
};

export { MyData };
