import { useTheme } from '@mui/material';

interface sxProps {
    backgroundColor: string;
}

export const sx = ({
    backgroundColor,
}: sxProps) => {
    const { palette } = useTheme();
    const css = {
        sxButton: {
            'default': {
                'color': palette.common.white,
                'backgroundColor': backgroundColor,
                '&:hover': {
                    'backgroundColor': backgroundColor,
                },
                'borderRadius': '0.1875rem',
            },
            'small': {
                fontSize: '0.875rem',
                padding: '0.375rem 1rem',
            },
            'medium': {
                fontSize: '0.9375rem',
                padding: '0.375rem 2.8125rem',
            },
            'big': {
                borderRadius: '0',
                fontSize: '0.9375rem',
                width: '100%',
                padding: '0.5rem 2.8125rem',
            },
        },
        sxIcon: {
            'small': {
                marginLeft: '0.5rem',
                fontSize: '1rem',
            },
            'medium': {
                marginLeft: '0.5rem',
                fontSize: '1rem',
            },
            'big': {
                marginLeft: '0.5rem',
                fontSize: '1.125rem',
            },
        },
    };
    return { css };
};
