import styled from 'styled-components';

export const ExtractHistory = styled.ul`
    max-width: 33.375rem;
    width: 100%;
`;

export const List = styled.li`
    width: 100%;
    position: relative;    
`;

export const Cols = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
