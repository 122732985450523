/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import { muiCheckbox } from './muiCheckbox';

declare module '@mui/material/styles' {

    interface Palette {
        dark: string;
        clouds: string;
        lightBlue: string;
        midnight: string;
        bluePortal: string;
        carminePink: string;
        nephritis: string;
        peach: string;

    }

    interface PaletteOptions {
        dark: string;
        clouds: string;
        lightBlue: string;
        midnight: string;
        bluePortal: string;
        carminePink: string;
        nephritis: string;
        peach: string;
    }
}

declare module '@mui/material/styles/createTypography' {
    interface FontStyle {
        fontPoppins: string;
        fontWeightSemiBold: number;
    }
}

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins',
        fontWeightSemiBold: 600,
    },
    components: {
        MuiButtonGroup: {
            defaultProps: {
                size: 'large',
            },
        },
        MuiCheckbox: {
            defaultProps: {
                ...muiCheckbox,
            },
            styleOverrides: {
                colorPrimary: {
                    '&.Mui-checked': {
                        color: '#010101',
                    },
                    ...{ color: '#6A6F7A' },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                size: 'large',
                disableRipple: true,
                sx: {
                    '&.MuiButton.primary': {
                        backgroundColor: '#D23B53',
                    },
                    '&.MuiButton.secondary': {
                        backgroundColor: '#010101',
                    },
                    ...{
                        borderRadius: '0 !important',
                        textTransform: 'initial',
                    },
                },
                style: {
                    boxShadow: 'none',
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: 'medium',
                sx: {
                    '&.MuiOutlinedInput-root > fieldset': {
                        borderWidth: '1px !important',
                        borderColor: '#DDDDE2 !important',
                    },
                },
            },
        },
    },
    palette: {
        dark: '#010101',
        clouds: '#ECF0F1',
        lightBlue: '#FBFEFF',
        midnight: '#353B48',
        bluePortal: '#4950E6',
        carminePink: '#D23B53',
        nephritis: '#27AE60',
        peach: '#E3B487',
    },
});

export { theme };
