import { useTheme } from '@mui/material';

export const sx = () => {
    const { typography } = useTheme();
    const css = {
        sxBox: {
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
        },
        sxKey: {
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightSemiBold,
        },
        sxValue: {
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightRegular,
            display: 'flex',
            alignItems: 'center',
        },
    };

    return { css };
};
