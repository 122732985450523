import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxDescription: {
            fontSize: matches ? '0.875rem' : '0.75rem',
            fontWeight: typography.fontWeightLight || 300,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
            height: '2.625rem',
            width: '100%',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            color: palette.dark,
        },
        sxStackInfo: {
            paddingTop: matches ? '0.9375rem' : '0.5rem',
            color: palette.dark,
            alignItems: 'flex-start',
        },
    };

    return { css };
};
