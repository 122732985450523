import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            padding: matches ? '1.875rem' : '0.9375rem',
        },
        sxDivider: {
            borderColor: palette.clouds,
        },
        sxMenuItem: {
            fontSize: '0.75rem',
        },
        sxBoxIcon: {
            height: '2.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        sxBoxButton: {
            display: 'flex',
            padding: matches ? '1.25rem 0' : '0 0 0.9375rem 0',
            justifyContent: 'center',
        },
        sxBoxCentralButton: {
            'padding': matches ? '0.75rem 0' : '1.375rem 0',
            'display': 'flex',
            '& > .menu-item': {
                maxWidth: '16.0625rem',
            },
        },
        sxFaqTitle: {
            fontWeight: matches ? typography.fontWeightBold :
                typography.fontWeightMedium,
            fontSize: matches ? '1.375rem' : '1.125rem',
        },
        sxFaqLegend: {
            fontWeight: typography.fontWeightLight,
            fontSize: matches ? '0.875rem' : '0.75rem',
        },
    };

    return { css };
};
