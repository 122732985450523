import React from 'react';

import { Box, Typography } from '@mui/material';
import { ItemProps } from '@interfaces';

import { sx } from './sx';

const Item: React.FC<ItemProps> = ({
    keyValue, value,
}: ItemProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxBox }}>
            <Typography
                sx={{ ...css.sxKey }}
                variant={'h3'}>
                {keyValue}
            </Typography>
            <Typography
                sx={{ ...css.sxValue }}
                variant={'h3'}>
                {value}
            </Typography>
        </Box>
    );
};

export { Item };
