import Eletro from '@img/icons-store/eletrodomesticos.png';
import Beleza from '@img/icons-store/beleza.png';
import Bebidas from '@img/icons-store/bebidas.png';
import Moveis from '@img/icons-store/moveis.png';
import Smartphone from '@img/icons-store/smartphones.png';
import Viagens from '@img/icons-store/viagens.png';

export const categories = [
    {
        title: 'Eletrodomésticos',
        image: Eletro,
    },
    {
        title: 'Beleza',
        image: Beleza,
    },
    {
        title: 'Smartphones',
        image: Smartphone,
    },
    {
        title: 'Móveis',
        image: Moveis,
    },
    {
        title: 'Bebidas',
        image: Bebidas,
    },
    {
        title: 'Viagens',
        image: Viagens,
    },
    {
        title: 'Eletrodomésticos',
        image: Eletro,
    },
];
