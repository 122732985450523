import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxDivider: {
            height: '1.875rem',
            borderColor: palette.dark,
            width: '0.125rem',
            borderWidth: '0rem 0.125rem 0rem 0rem',
            padding: '0 0.75rem',
        },
        sxBox: {
            padding: '0 0.9375rem',
            display: 'flex',
            alignItems: 'center',
        },
        sxBoxInfo: {
            paddingLeft: '1.25rem',
        },
        sxIcon: {
            fontSize: '1.25rem',
            color: palette.dark,
        },
        sxMessage: {
            fontWeight: 700,
            fontSize: '0.875rem',
            paddingBottom: '0.3125rem',
        },
        sxDate: {
            fontSize: '0.875rem',
        },
    };
    return { css };
};
