import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';

const SeePartner: React.FC = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();
    return (
        <Typography
            variant='caption'
            sx={{ ...css.sxSeeProductText }}>
            {matches ? 'Ver produtos do parceiro' :
                'Ver parceiro'
            }
            <I
                style={{ ...css.sxIcon }}
                icon={['far', 'arrow-right-long']} />
        </Typography>
    );
};

export { SeePartner };
