import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            backgroundColor: palette.common.white,
            boxShadow: '0 0.1875rem 0.375rem #00000029',
            border: `0.0625rem solid ${palette.lightBlue}`,
            padding: matches ? '1.5625rem' : '0.9375rem',
            borderRadius: '0.375rem',
            marginBottom: '1.5625rem',
        },
        sxBoxInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '1.5625rem',
        },
        sxTitle: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '1.375rem',
        },
        sxLegend: {
            fontWeight: typography.fontWeightLight,
            fontSize: '0.75rem',
        },
        sxSeeMoreLink: {
            display: 'flex',
            alignItems: 'center',
            height: '1.25rem',
            cursor: 'pointer',
            textDecoration: 'none',
            color: palette.dark,
            minWidth: '5.125rem',
        },
        sxSeeMoreText: {
            'fontSize': '0.875rem',
            'fontWeight': typography.fontWeightMedium,
            'paddingRight': '0.4375rem',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        sxSeeMoreIcon: {
            fontSize: '0.875rem',
        },
    };
    return { css };
};
