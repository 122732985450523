export const tabsData = [
    {
        label: 'detalhes de pts',
    },
    {
        label: 'pts a expirar',
    },
];

export const periodsData = [
    {
        label: '1 mês',
        value: '1m',
    },
    {
        label: '3 mêses',
        value: '3m',
    },
    {
        label: '6 mêses',
        value: '6m',
    },
    {
        label: '1 ano',
        value: '1y',
    },
    {
        label: '+ de 1 ano',
        value: '1ym',
    },
];
