import React from 'react';

import {
    Stack,
    Box,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { FilterButton } from './components/filter-button';
import { Input } from './styles';
import { sx } from './sx';

interface SearchBarProps {
    onClickSearch?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
    onClickSearch,
}: SearchBarProps) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxBox }}>
            <IconButton
                onClick={onClickSearch}
                sx={{ ...css.sxIconButton }}>
                <I
                    style={{ ...css.sxIconSearch }}
                    icon={['far', 'magnifying-glass']} />
            </IconButton>
            <Input
                placeholder={matches ? 'O que você procura?' : 'Buscar'}/>
            <Stack direction={'row'}>
                <FilterButton
                    position='left'
                    id={'produto'} filter={'produtos'}>
                    <input
                        defaultChecked
                        id={'produto'}
                        value={'produtos'}
                        name='filter'
                        type='radio' />
                </FilterButton>
                <FilterButton
                    position='right'
                    id={'marca'} filter={'marcas'}>
                    <input
                        id={'marca'}
                        value={'marcas'}
                        name='filter'
                        type='radio' />
                </FilterButton>
            </Stack>
        </Box>
    );
};

export { SearchBar };
