import React from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { QuizItemProps } from '@interfaces';

import { sx } from './sx';
import { Elipse1, Elipse2 } from './styles';

const QuizItem: React.FC<QuizItemProps> = ({
    color, title, legend, link, quantityQuestions,
}: QuizItemProps) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx({
        color: color,
        matches: matches,
    });

    return (
        <Link
            style={{ ...css.sxLink, position: 'relative' }}
            to={link ? link : '#!'}>
            <Box sx={{ ...css.sxBox }}>
                <Elipse1 color={color} matches={matches} />
                <Elipse2 color={color} matches={matches} />
                <Box>
                    <Typography
                        variant='h3'
                        sx={{ ...css.sxTitle }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant={'body1'}
                        sx={{ ...css.sxLegend }}
                    >
                        {legend}
                    </Typography>
                </Box>
                <Box sx={{ ...css.sxBoxQuestion }}>
                    <I
                        style={{ ...css.sxIconQuestion }}
                        icon={['fas', 'circle-question']} />
                    <Typography
                        style={{ ...css.sxQuestionText }}
                        variant='caption'>
                        {quantityQuestions} perguntas
                    </Typography>
                </Box>
                <Box sx={{ ...css.sxBoxArrow }}>
                    <I
                        style={{ ...css.sxIconArrow }}
                        icon={['far', 'arrow-right-long']} />
                </Box>
            </Box>
        </Link>
    );
};

export { QuizItem };
