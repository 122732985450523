import React, { ForwardRefExoticComponent, RefAttributes } from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { ModalStaticProps, DialogProps } from '@interfaces';
import { Typography, Box, IconButton } from '@mui/material';

import { sx } from './sx';

const STATIC_PROPS: ModalStaticProps = {
    show: () => Boolean,
    hide: () => Boolean,
};

const Dialog: ForwardRefExoticComponent<
    DialogProps & RefAttributes<typeof STATIC_PROPS>>=
    React.forwardRef((props, ref) => {
        const { title, children, actionOnHandleClose, className } = props;
        const { css } = sx();
        const [open, setOpen] = React.useState<boolean>(() => false);


        const handleOpen = () => {
            setOpen((prev: boolean): boolean => prev = true);
            if (!open) {
                document.body.classList.add('overflow-hidden');
            }
        };

        const handleClose = () => {
            setOpen((prev: boolean): boolean => prev = false);

            if (open === true) {
                document.body.classList.remove('overflow-hidden');
            }

            if (actionOnHandleClose) {
                actionOnHandleClose();
            }
        };

        React.useImperativeHandle(ref, () => ({
            show: () => handleOpen() as any,
            hide: () => handleClose() as any,
        }));

        return (
            <React.Fragment>
                {open && (
                    <Box sx={{ ...css.sxOverlay }}>
                        <Box className={className} sx={{ ...css.sxRoot }}>
                            <Box className={className} sx={{ ...css.sxHeader }}>
                                <Typography
                                    sx={{ ...css.sxTitle }}
                                    variant='h2'>
                                    {title}
                                </Typography>
                                <IconButton
                                    onClick={handleClose}
                                    sx={{ ...css.sxIconButton }}>
                                    <I
                                        style={{ ...css.sxIconClose }}
                                        icon={['far', 'times']} />
                                </IconButton>
                            </Box>
                            {children}
                        </Box>
                    </Box>
                )}
            </React.Fragment>
        );
    });

export { Dialog };
