import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const matches2 = useMediaQuery('(min-width:600px)');
    const css = {
        sxContainer: {
            backgroundColor: palette.lightBlue,
            paddingBottom: matches ? '0' : '3.125rem',
        },
        sxBox: {
            padding: '1.5625rem',
            paddingBottom: '3.125rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: matches2 ? 'normal' : 'center',
        },
        sxBoxImg: {
            maxWidth: '9.5625rem',
            width: '100%',
        },
        sxBoxInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '0.375rem',
            flexDirection: matches2 ? 'row' :'column',
            alignItems: matches2 ? 'normal' : 'center',
        },
        sxTextInfo: {
            'color': palette.midnight,
            'fontSize': '0.8125rem',
            'textAlign': 'center',
            '&.link': {
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
            '&.divisor': {
                'margin': '0 0.375rem',
                'marginTop': '0.25rem',
                'cursor': 'default',
            },
        },
        sxBoxLinks: {
            display: 'flex',
            paddingTop: matches2 ? 0 : '0.375rem',
        },
        sxLink: {
            textDecoration: 'none',
        },
    };

    return { css };
};
