import { Box, Typography } from '@mui/material';

import { AuthenticatedContentProps } from '@interfaces';

import { sx } from './sx';

const AuthenticatedContent: React.FC<AuthenticatedContentProps> = (
    {
        title, legend, children,
    }: AuthenticatedContentProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxBoxGlobal }}>
            <Box
                sx={{ ...css.sxBox }}>
                {title ?
                    <>
                        <Box sx={{ ...css.sxBoxHeader }}>
                            <Typography variant='h2'
                                sx={{ ...css.sxTitle }}>
                                {title}
                            </Typography>
                            {legend &&
                            <Typography variant='body1'
                                sx={{ ...css.sxLegend }}>
                                {legend}
                            </Typography>
                            }
                        </Box>
                        <Box sx={{ ...css.sxBoxChildren }}>
                            {children}
                        </Box>
                    </> :
                    <Box sx={{ ...css.sxBoxChildren2 }}>
                        {children}
                    </Box>
                }
            </Box>
        </Box>
    );
};

export { AuthenticatedContent };
