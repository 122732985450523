import { useTheme } from '@mui/material';

export const sx = (expand: boolean) => {
    const { palette } = useTheme();
    const css = {
        sxBox: {
            // border: `0.0625rem solid ${palette.clouds}`,
        },
        sxBoxTitle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
        },
        sxDivider: {
            borderColor: palette.clouds,
        },
        sxIcon: {
            padding: '0.9375rem',
            fontSize: '1rem',
            color: palette.dark,
        },
        sxFilterName: {
            paddingLeft: '0.9375rem',
            textTransform: 'uppercase',
            fontSize: '0.875rem',
            fontWeight: expand ? 600 : 400,
        },
        sxBoxButton: {
            display: 'flex',
            justifyContent: 'center',
            padding: '0.625rem',
            borderBottom: `0.0625rem solid ${palette.clouds}`,
        },
        sxStack: {
            'opacity': 0,
            'visibility': 'visible',
            'transition': 'visibility 0.33s linear 0.33s, opacity 0.33s linear',
            '&.show': {
                visibility: 'visible',
                opacity: 1,
                transitionDelay: '0s',
                maxHeight: '18.125rem',
                overflowY: 'auto',
            },
            '&.hide': {
                'opacity': '0',
                'visibility': 'hidden',
                'overflow': 'hidden',
                'height': 0,
                'transition': 'visibility 1s linear 1s, opacity 1s linear',
            },
        },
    };
    return { css };
};
