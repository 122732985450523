/* eslint-disable max-len */
export const helpData = [
    {
        topic: 'Pontos',
        key: 'pontos',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat augue diam.',
        questions: [
            {
                question: 'Como funciona os pontos?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Donec placerat augue diam?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Consectetur adipiscing?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
        ],
    },
    {
        topic: 'Resgate',
        key: 'resgate',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat augue diam.',
        questions: [
            {
                question: 'Como funciona o resgate?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Donec placerat augue diam?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Consectetur adipiscing?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
        ],
    },
    {
        topic: 'Campanhas',
        key: 'campanhas',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat augue diam.',
        questions: [
            {
                question: 'Como funciona as campanhas?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Donec placerat augue diam?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Consectetur adipiscing?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
        ],
    },
    {
        topic: 'Dados Cadastrais',
        key: 'dados',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat augue diam.',
        questions: [
            {
                question: 'Meus dados estão seguros?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Donec placerat augue diam?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Consectetur adipiscing?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
        ],
    },
    {
        topic: 'Fale Conosco',
        key: 'faq',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec placerat augue diam.',
        questions: [
            {
                question: 'Como funciona o atendimento?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Donec placerat augue diam?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
            {
                question: 'Consectetur adipiscing?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor sollicitudin metus a lobortis. Donec non consectetur sapien. Duis nec facilisis libero, eget dictum purus. Cras sit amet mauris nibh. Mauris ornare enim vel commodo elementum. Proin bibendum pretium turpis et rhoncus. Fusce eu pharetra lacus. Nunc dui elit, auctor quis diam sed, bibendum finibus nisi.',
            },
        ],
    },
];
