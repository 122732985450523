import React from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { DashboardItemProps } from '@interfaces';

import { sx } from './sx';

const DashboardItem: React.FC<DashboardItemProps> = ({
    color, icon, title, legend, link,
}: DashboardItemProps) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx({
        color: color,
        matches: matches,
    });

    return (
        <Link
            style={{ ...css.sxLink }}
            to={link ? link : '#!'}>
            <Box sx={{ ...css.sxBox }}>
                <Box sx={{ ...css.sxBoxIcon }}>
                    <I
                        icon={icon}
                        style={{ ...css.sxIcon }}/>
                </Box>
                <Typography
                    variant='h3'
                    sx={{ ...css.sxTitle }}
                >
                    {title}
                </Typography>
                <Typography
                    variant={'body1'}
                    sx={{ ...css.sxLegend }}
                >
                    {legend}
                </Typography>
                <Box sx={{ ...css.sxBoxArrow }}>
                    <I
                        style={{ ...css.sxIconArrow }}
                        icon={['far', 'arrow-right-long']} />
                </Box>
            </Box>
        </Link>
    );
};

export { DashboardItem };
