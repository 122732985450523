import { useMediaQuery } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            padding: '0.9375rem',
            display: 'flex',
        },
        sxBoxImage: {
            display: 'flex',
            width: '5rem',
            height: '5.25rem',
            marginRight: '1.25rem',
        },
        sxBoxContent: {
            display: 'flex',
            width: '100%',
            flexDirection: matches ? 'row': 'column',
            justifyContent: 'space-between',
        },
    };
    return { css };
};
