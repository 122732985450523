export const tabsData = [
    {
        label: 'Perfil',
        path: '/meus-dados/perfil',
    },
    {
        label: 'Minha senha',
        path: '/meus-dados/senha',
    },
];
