import { useMediaQuery, useTheme } from '@mui/material';

export const sx = ({
    clientColor,
}: {clientColor: string}) => {
    const matches = useMediaQuery('(min-width:900px)');
    const { palette, typography } = useTheme();
    const css = {
        sxTabs: {
            'height': '100%',
            'padding': matches ? '0 1.875rem' : '0 0.9375rem',
            'borderBottom': `0.0625rem solid ${palette.clouds}`,
            '& > .MuiTabs-scroller': {
                '& > .MuiTabs-indicator': {
                    'height': '0.1875rem',
                    'backgroundColor': clientColor,
                },
                '& > .MuiTabs-flexContainer': {
                    'height': '100%',
                    '& > .MuiTab-root': {
                        'width': matches ? 'auto' : '50%',
                        'padding': matches ? '1.875rem' : '1.0625rem',
                        'fontSize': matches ? '1rem' : '0.875rem',
                        'fontWeight': typography.fontWeightMedium,
                        'textTransform': 'none',
                        '&.Mui-selected': {
                            color: clientColor,
                        },
                    },
                },
            },
        },
        sxDividerBalance: {
            borderColor: '#93999B',
            borderStyle: 'dashed',
        },
        sxBoxBalance: {
            backgroundColor: '#F4F8F9',
            padding: matches ? '0.625rem 1.875rem' : '0.625rem 0.9375rem',
        },
        sxTitleBalance: {
            fontWeight: typography.fontWeightMedium,
            fontSize: matches ? '1rem' : '0.875rem',
            padding: '0.75rem 0',
        },
        sxBoxInfoBalance: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0.75rem 0.9375rem',
        },
        sxTitleInfoBalance: {
            fontWeight: typography.fontWeightRegular,
            fontSize: '0.875rem',
        },
        sxPointsBalance: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '0.875rem',
        },
        sxInfoUpdate: {
            fontSize: '0.75rem',
        },
        sxBoxInfoUpdate: {
            padding: matches ? '0.5rem 1.875rem' : '0.25rem 0.9375rem',
        },
        sxStackPeriods: {
            padding: matches ? '0.625rem 1.875rem' : '0.625rem 0.9375rem',
            paddingBottom: '1.375rem',
            overflowX: 'auto',
        },
        sxPeriodsSelect: {
            fontSize: '0.75rem',
            padding: matches ? '0 1.875rem' : '0 0.9375rem',
        },
        sxTabPainel: {
            padding: 0,
        },
        sxBoxHistory: {
            backgroundColor: '#F4F8F9',
            padding: matches ? '1.875rem' : '0.9375rem',
        },
    };
    return { css };
};
