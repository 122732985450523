import React from 'react';
import { TableCell, TableRow } from '@mui/material';

interface TableListProps {
    keyValue: string;
    value: string;
}

import { sx } from './sx';

const TableList: React.FC<TableListProps> = ({
    keyValue, value,
}: TableListProps) => {
    const { css } = sx();
    return (
        <TableRow sx={{ ...css.sxRow }}>
            <TableCell sx={{ ...css.sxCell1 }}>
                {keyValue}
            </TableCell>
            <TableCell align='right' sx={{ ...css.sxCell2 }}>
                {value}
            </TableCell>
        </TableRow>
    );
};

export { TableList };
