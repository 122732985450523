import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxLink: {
            height: '100%',
            minHeight: '13.875rem',
            width: '100%',
            // maxWidth: matches ? '14.5625rem' : '100%',
            textDecoration: 'none',
        },
        sxBoxImage: {
            height: '100%',
            width: '100%',
            maxHeight: '9.3125rem',
            borderRadius: '0.1875rem 0.1875rem 0 0',
        },
        sxBoxInfo: {
            padding: '0.75rem 1.5625rem',
            border: `0.0625rem solid ${palette.clouds}`,
            borderRadius: '0 0 0.1875rem 0.1875rem',
            position: 'relative',
            height: '9.1875rem',
            backgroundColor: palette.lightBlue,
        },
        sxTitle: {
            color: palette.midnight,
            fontSize: '1.125rem',
            fontWeight: typography.fontWeightBold,
            paddingBottom: '0.375rem',
        },
        sxLegend: {
            color: palette.midnight,
            fontSize: '0.75rem',
            fontWeight: typography.fontWeightRegular,
        },
        sxBoxArrow: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            padding: '1.5625rem',
        },
        sxIconArrow: {
            color: palette.midnight,
        },
    };
    return { css };
};
