import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@mui/material';

import { StoreItemProps } from '@interfaces';

import { sx } from './sx';
import { Image } from './styles';

const StoreItem: React.FC<StoreItemProps> = ({
    link, image, children, product,
}: StoreItemProps) => {
    const { css } = sx({
        product: product,
    });
    return (
        <Link style={{ ...css.sxLink, flexDirection: 'column' }} to={link}>
            <Box sx={{ ...css.sxBoxImage }}>
                <Image src={image} alt="" />
            </Box>
            <Box sx={{ ...css.sxBoxChildren }}>
                {children}
            </Box>
        </Link>
    );
};

export { StoreItem };
