import React from 'react';

import {
    Box,
    Container,
} from '@mui/material';

import { SimpleBaseProps } from '@interfaces';

import { AuthenticatedContent } from '../content';
import { sx } from './sx';


const SimpleBase: React.FC<SimpleBaseProps> = ({
    title, legend, children, noPadding,
}: SimpleBaseProps) => {
    const { css } = sx({
        noPadding: noPadding,
    });
    return (
        <Box sx={{ ...css.sxBox }}>
            <Container maxWidth='lg'
                sx={{ ...css.sxContainer }}>
                <AuthenticatedContent
                    title={title}
                    legend={legend}>
                    {title ?
                        <Box sx={{ ...css.sxBoxChildren }}>
                            {children}
                        </Box> :
                        children
                    }
                </AuthenticatedContent>
            </Container>
        </Box>
    );
};

export { SimpleBase };
