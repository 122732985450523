import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { typography, palette } = useTheme();
    const css = {
        sxBoxGlobal: {
            backgroundColor: palette.lightBlue,
            display: 'flex',
            flexDirection: 'column',
        },
        sxBox: {
            marginBottom: matches ? '1.875rem' : '0.9375rem',
            boxShadow: '0 0.1875rem 0.375rem #00000029',
            borderRadius: '0.375rem',
            backgroundColor: palette.common.white,
        },
        sxBoxHeader: {
            borderRadius: '0.375rem 0.375rem 0 0',
            backgroundColor: palette.common.white,
            borderBottom: `0.0625rem solid ${palette.clouds}`,
            padding: matches ? '1.875rem' : '0.9375rem',
        },
        sxTitle: {
            fontWeight: matches ?
                typography.fontWeightBold : typography.fontWeightMedium,
            fontSize: matches ? '1.875rem' : '1.375rem',
        },
        sxLegend: {
            fontWeight: typography.fontWeightLight,
            fontSize: matches ? '1rem' : '0.75rem',
        },
        sxBoxChildren: {
            backgroundColor: palette.common.white,
            borderRadius: '0.375rem',
            // minHeight: 'calc(100vh - 393px)',
        },
        sxBoxChildren2: {
            backgroundColor: palette.common.white,
            borderRadius: '0.375rem',
            // minHeight: 'calc(100vh - 317px)',
        },
    };
    return { css };
};
