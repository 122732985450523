import { useTheme } from '@mui/material';


export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxBoxPage: {
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            backgroundColor: palette.common.white,
            zIndex: 9999,
        },
        sxStack: {
            borderTop: `0.0625rem solid ${palette.clouds}`,
        },
        sxIconMenu: {
            fontSize: '1rem', padding: '0.875rem 0',
        },
    };

    return { css };
};
