import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faClipboardListCheck as listCheck,
    faSquare,
    faSquareCheck,
    faMegaphone,
    faHouse as houseLight,
    faClipboardListCheck as clipboardLight,
    faCartShopping as cartLight,
} from '@fortawesome/pro-light-svg-icons';

import {
    faCartShopping,
    faBarsStaggered,
    faGear,
    faClipboardListCheck,
    faBoxesStacked,
    faRightFromBracket,
    faChevronRight,
    faArrowRightLong,
    faArrowLeftLong,
    faArrowLeft,
    faArrowRight,
    faTimes,
    faCircleMinus,
    faCalendarClock,
    faMagnifyingGlass,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faCaretDown,
    faCaretUp,
    faChartSimple,
    faGrid2,
    faGaugeCircleBolt,
    faCircleQuestion,
    faCamera,
    faEye,
    faEyeSlash,
    faCircle,
    faClock,
    faHourglassClock,
    faCheck,
    faChevronRight as chevronRightSolid,
    faArrowRight as arrowRightSolid,
    faMinus,
    faPlus,
    faArrowUpArrowDown,
    faSliders,
    faHouse,
    faMegaphone as megaphoneSolid,
    faHouse as houseSolid,
    faClipboardListCheck as clipboardSolid,
    faCartShopping as cartSolid,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faCaretDown,
    faCartShopping,
    faBarsStaggered,
    faGear,
    faClipboardListCheck,
    listCheck,
    faBoxesStacked,
    faRightFromBracket,
    faChevronRight,
    faChartSimple,
    faGrid2,
    faArrowRightLong,
    faGaugeCircleBolt,
    faCircleQuestion,
    faCaretUp,
    faCamera,
    faEye,
    faEyeSlash,
    faArrowLeftLong,
    faArrowLeft,
    faCircle,
    faTimes,
    faClock,
    faHourglassClock,
    faCheck,
    faCircleMinus,
    faCalendarClock,
    chevronRightSolid,
    arrowRightSolid,
    faMinus,
    faPlus,
    faMagnifyingGlass,
    faArrowRight,
    faChevronDown,
    faChevronUp,
    faSquare,
    faSquareCheck,
    faArrowUpArrowDown,
    faSliders,
    faChevronLeft,
    faHouse,
    faMegaphone,
    houseLight,
    clipboardLight,
    cartLight,
    megaphoneSolid,
    houseSolid,
    clipboardSolid,
    cartSolid,
);
