import { Button } from '@mui/material';

interface ButtonResetFiltersProps {
    text: string;
}

import { sx } from './sx';

const ButtonResetFilters = (
    { text }: ButtonResetFiltersProps) => {
    const { css } = sx();

    return (
        <Button
            variant="outlined"
            fullWidth
            size='large'
            sx={{ ...css.sxButton }}
        >
            {text}
        </Button>
    );
};

export { ButtonResetFilters };
