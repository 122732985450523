export const textSize = {
    big: 28,
    medium: 18,
    small: 16,
    tiny: 14,
};

export const customSizePts = {
    big: 15,
    medium: 12,
    small: 10,
    tiny: 8,
};

export const customMarginSize = {
    big: '-0.25rem',
    medium: '-0.0625rem',
    small: '-0.125rem',
    tiny: '-0.125rem',
};
