import { useTheme } from '@mui/material';

export const sx = () => {
    const { typography, palette } = useTheme();
    const css = {
        sxBox: {
            width: '100%',
            display: 'flex',
            position: 'relative',
            backgroundColor: palette.common.white,
        },
        sxIconButton: {
            fontSize: '1.125rem',
            color: palette.dark,
            padding: '1.25rem 1.125rem',
            position: 'absolute',
            borderRadius: 0,
        },
        sxTitle: {
            fontSize: '1.125rem',
            color: palette.dark,
            padding: '1.1875rem 1.125rem',
            fontWeight: typography.fontWeightSemiBold,
            textAlign: 'center',
            width: '100%',
        },
    };

    return { css };
};
