import React from 'react';

import {
    Box,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { TopicProps } from '@interfaces';

import { sx } from './sx';

const Topic: React.FC<TopicProps> = ({
    legend, title, keyValue,
}: TopicProps) => {
    const { css } = sx();
    return (
        <Link to={`/central-de-ajuda/${keyValue}`} style={{
            ...css.sxLink,
        }}>
            <Box sx={{ ...css.sxBox }}>
                <Typography
                    sx={{ ...css.sxTitle }}
                    variant='h3'>
                    {title}
                </Typography>
                <Typography
                    sx={{ ...css.sxLegend }}
                    variant='body1'>
                    {legend}
                </Typography>
                <Typography
                    sx={{ ...css.sxLinkText }}
                    variant='caption'>
                    Saiba mais
                </Typography>
            </ Box>
        </Link>
    );
};

export { Topic };
