import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import { CategoryButtonProps } from '@interfaces';

import { sx } from './sx';
import { Icon } from './styles';

const CategoryButton: React.FC<CategoryButtonProps> = ({
    icon, title,
}: CategoryButtonProps) => {
    const { css } = sx();
    return (
        <Link style={{ ...css.sxLink }} to={''}>
            <Button sx={{ ...css.sxButton }}>
                <Icon
                    src={icon} alt="" />
                <Typography
                    sx={{ ...css.sxTitle }}
                    variant='caption'>
                    {title}
                </Typography>
            </Button>
        </Link>
    );
};

export { CategoryButton };
