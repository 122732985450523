import React from 'react';
import {
    Box,
    Container,
    Tabs,
    Tab,
    Stack,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
    Menu,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    AvatarGroup,
    MenuItem,
    Points,
} from '@components';

import {
    HeaderProps,
} from '@interfaces';

import { userMenu, tabsData } from '@static/menus';
import { Logo } from './styles';
import { sx } from './sx';
import { useLocation, useNavigate } from 'react-router-dom';

const Header: React.FC<HeaderProps> = ({
    clientColor, logo,
}: HeaderProps) => {
    const matches = useMediaQuery('(min-width:900px)');
    const { css } = sx({
        clientColor: clientColor,
        matches: matches,
    });
    const location = useLocation();
    const navigate = useNavigate();

    const [
        avatarMenu,
        setAvatarMenu,
    ] = React.useState<null | HTMLElement>(null);
    const openAvatarMenu = Boolean(avatarMenu);

    const handleShowMenuAvatar =
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAvatarMenu(event.currentTarget);
        };

    const handleCloseMenuAvatar = () => {
        setAvatarMenu(null);
    };

    const handleActiveTab = () => {
        switch (location.pathname) {
        case '/':
            return 0;
        case '/meus-dados/perfil':
            return 1;
        case '/meus-dados/senha':
            return 1;
        case '/fale-conosco':
            return 2;
        default:
            return false;
        }
    };


    return (
        <Box sx={{ ...css.sxBoxHeader }}>
            <Container sx={{ ...css.sxContainer }}>
                {!matches &&
                    <IconButton
                        onClick={() => navigate('/menu')}
                        sx={{ ...css.sxIconButtonMenu }}>
                        <I
                            style={{ ...css.sxIconMenu }}
                            icon={['far', 'bars-staggered']} />
                    </IconButton>
                }
                <Box sx={{ ...css.sxBoxLogo0 }}>
                    <Box sx={{ ...css.sxBoxLogo }}>
                        <Logo onClick={() => navigate('/')} src={logo} alt='' />
                    </Box>
                </Box>
                {matches &&
                    <Stack
                        spacing={6.5}
                        direction={'row'}>
                        <Tabs
                            value={handleActiveTab()}
                            aria-label="basic tabs example"
                            sx={{ ...css.sxTabs }}>
                            {tabsData.map(({ label, path }) => (
                                <Tab
                                    key={label}
                                    label={label}
                                    onClick={() => navigate(path)}/>
                            ))}
                        </Tabs>
                        <Stack direction="row"
                            divider={
                                <Divider
                                    orientation="vertical"
                                    sx={{ ...css.sxDivider }} />}
                            sx={{ ...css.sxStack }}
                            spacing={2}>
                            <Box
                                onClick={() => navigate('/carrinho')}
                                sx={{ ...css.sxBoxSection }}>
                                <Typography
                                    variant='caption'
                                    sx={{ ...css.sxItemsText }}>
                                    Meu carrinho:
                                </Typography>
                                <Box sx={{ paddingTop: '0.1875rem' }}>
                                    <I
                                        icon={['far', 'cart-shopping']}
                                        style={{ ...css.sxCartIcon }} />
                                    <Typography
                                        variant='caption'
                                        sx={{ ...css.sxCartItemsText }}>
                                        4 itens
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                onClick={() => navigate('/extrato')}
                                sx={{ ...css.sxBoxSection }}>
                                <Typography
                                    variant='caption'
                                    sx={{ ...css.sxItemsText }}>
                                    Você tem:
                                </Typography>
                                <Points size={'medium'} value={'486'} />
                            </Box>
                            <Box
                                aria-controls={
                                    openAvatarMenu ? 'user-menu' :
                                        undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={
                                    openAvatarMenu ? 'true' :
                                        undefined
                                }
                                onMouseEnter={matches ? (e) =>
                                    handleShowMenuAvatar(e as any) :
                                    () => null}
                                sx={{ ...css.sxBoxAvatar }}>
                                <AvatarGroup
                                    align={'right'}
                                    size={'tiny'}
                                    src={''}
                                    title={'Amanda Monteiro'}
                                    legend={'Portal administrador'}/>
                                <IconButton sx={{
                                    ...css.sxIconButtonMenuUser,
                                }}>
                                    <I
                                        icon={
                                            ['fas', openAvatarMenu ?
                                                'caret-up' : 'caret-down']}
                                        style={{ ...css.sxCaretIcon }} />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Stack>
                }
                {matches && avatarMenu &&
                    <Menu
                        id="user-menu"
                        anchorEl={avatarMenu}
                        open={openAvatarMenu}
                        onClose={handleCloseMenuAvatar}
                        MenuListProps={{
                            onMouseLeave: handleCloseMenuAvatar,
                        }}
                        transformOrigin={{
                            horizontal: 'right', vertical: 'top',
                        }}
                        anchorOrigin={{
                            horizontal: 'right', vertical: 'bottom',
                        }}>
                        {userMenu.map(({
                            icon, title, color, link }, i) => (
                            <MenuItem
                                key={`${title}-${i}`}
                                icon={icon}
                                mode={'menuIcon'}
                                borderType={'bottom'}
                                color={color}
                                title={title}
                                onClick={() => navigate(link ? link : '')}>
                                <I
                                    style={{ fontSize: '1rem' }}
                                    icon={
                                        ['far', 'chevron-right']}
                                />
                            </MenuItem>
                        ))}
                    </Menu>
                }
                {!matches &&
                    <AvatarGroup
                        onClick={() => navigate('/user-menu')}
                        align={'bottom'}
                        size={'extraTiny'}
                        src={''} />
                }
            </Container>
        </Box>
    );
};

export { Header };
