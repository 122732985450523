import { useMediaQuery } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            paddingBottom: '0.625rem',
            position: 'relative',
            maxWidth: matches ? '30.5625rem' : '100%',
        },
    };
    return {
        ...css,
    };
};
