import styled from 'styled-components';

export const Form = styled.form`
    .MuiFormControl-root {
        width: 100%;
        padding: 0.9375rem 1.25rem;

        @media (max-width: 900px) {
            padding: 0.625rem 0;
        }

        & > .MuiInputBase-root {
            & > .MuiOutlinedInput-input {
                font-size: 0.875rem;
                padding: 0.625rem 1.25rem;
                color: #010101;
            }
        }
    }
`;

export const FakeInput = styled.div`
    width: 100%;
    padding: 0.625rem 1.25rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    color: #010101;
    background-color: #F9FAFE;
    border: 0.0625rem solid #EAEAEA;
    font-family: 'Poppins';
`;
