import React from 'react';

import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { CampaignItemProps } from '@interfaces';

import { Image } from './styles';
import { sx } from './sx';

const CampaignItem: React.FC<CampaignItemProps> = ({
    image, title, legend, link, onClick,
}: CampaignItemProps) => {
    const { css } = sx();

    return (
        <Link
            onClick={onClick}
            style={{ ...css.sxLink }}
            to={link ? link : ''}>
            <Box sx={{ ...css.sxBoxImage }}>
                <Image src={image} alt={title} />
            </Box>
            <Box sx={{ ...css.sxBoxInfo }}>
                <Typography
                    variant='h3'
                    sx={{ ...css.sxTitle }}>
                    {title}
                </Typography>
                <Typography
                    variant='body1'
                    sx={{ ...css.sxLegend }}>
                    {legend}
                </Typography>
                <Box sx={{ ...css.sxBoxArrow }}>
                    <I
                        style={{ ...css.sxIconArrow }}
                        icon={['far', 'arrow-right-long']} />
                </Box>
            </Box>
        </Link>
    );
};

export { CampaignItem };
