import React from 'react';
import {
    Box,
    Grid,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { categories } from '@static/categories-store';
import {
    StoreItem,
    ProductGrid,
    Layout,
} from '@components';

import { SearchBar } from '../components/search-bar';
import { CategoryButton } from './components/category-button';
import { SectionStore } from './components/section-store';
import { SeePartner } from './components/see-partner';

import { sx } from './sx';
import { settings } from './slider-settings';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

const Store: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:600px)');
    const sliderPartners = React.useRef<any>(null);
    const sliderProducts = React.useRef<any>(null);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout.SimpleBase>
            <SearchBar onClickSearch={() => navigate('/resultados')}/>
            <Grid container sx={{
                ...css.sxGridContainer,
            }}>
                {categories.map((category, i) => (
                    <Grid
                        key={`${category.title}-${i}`}
                        sx={{ ...css.sxGridItem }}
                        item md={1.71} lg={1.71} sm={4} xs={4}>
                        <CategoryButton
                            icon={category.image}
                            title={category.title} />
                    </Grid>
                ))}
            </Grid>
            <SectionStore
                title='Veja produtos por parceiro'
                legend='Veja os principais produtos de nossos parceiros'>
                <Box sx={{ ...css.sxBoxSlider }}>
                    <Slider
                        ref={sliderPartners} {...settings}
                        className='collections-slider'>
                        {[0, 1, 2, 3, 4].map((i) => (
                            <StoreItem
                                key={`partner-${i}`}
                                link={''}
                                image={'https://cdn.discordapp.com/attachments/1044241266134745202/1082652690439487568/mc.png'}>
                                <SeePartner />
                            </StoreItem>
                        ))}
                    </Slider>
                    <Box sx={{ ...css.sxBoxArrow }}>
                        <IconButton
                            onClick={() => sliderPartners.current.slickNext()}
                            sx={{ ...css.sxButtonArrow }}>
                            <I
                                style={{ ...css.sxArrowIcon }}
                                icon={['far', 'chevron-right']} />
                        </IconButton>
                    </Box>
                </Box>
            </SectionStore>
            <SectionStore
                title='Uma seleção de ofertas especiais para você'
                legend='Veja as principais ofertas de nossos parceiros'>
                <Box sx={{ ...css.sxBoxSlider }}>
                    {matches ?
                        <>
                            <Slider
                                ref={sliderProducts} {...settings}
                                className='collections-slider'>
                                {[0, 1, 2, 3, 4].map((i) => (
                                    <ProductGrid
                                        key={`product-${i}`}
                                        link={'/produto'}
                                        image={'https://cdn.discordapp.com/attachments/1044241266134745202/1085891256829349929/wap.png'}
                                        description={
                                            // eslint-disable-next-line max-len
                                            'Lavadora de Alta Pressão Wap Eco Fit 2200 1500psi ededwedwed 977giuguiui'}
                                        points={'371.00'}
                                        partnerLogo={'https://cdn.discordapp.com/attachments/1044241266134745202/1082261363788877845/portal-logo.png'} />
                                ))}
                            </Slider>
                            <Box sx={{ ...css.sxBoxArrow }}>
                                <IconButton
                                    onClick={() =>
                                        sliderProducts.current.slickNext()}
                                    sx={{ ...css.sxButtonArrow }}>
                                    <I
                                        style={{ ...css.sxArrowIcon }}
                                        icon={['far', 'chevron-right']} />
                                </IconButton>
                            </Box>
                        </> :
                        <Grid container spacing={2}>
                            {[0, 1, 2, 3].map((i) => (
                                <Grid
                                    key={`product-grid-${i}`}
                                    item md={3} lg={3} sm={6} xs={6}>
                                    <ProductGrid
                                        link={'/produto'}
                                        image={'https://cdn.discordapp.com/attachments/1044241266134745202/1085891256829349929/wap.png'}
                                        description={
                                        // eslint-disable-next-line max-len
                                            'Lavadora de Alta Pressão Wap Eco Fit 2200 1500psi ededwedwed 977giuguiui'}
                                        points={'371.00'}
                                        partnerLogo={'https://cdn.discordapp.com/attachments/1044241266134745202/1082261363788877845/portal-logo.png'} />
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Box>
            </SectionStore>

        </Layout.SimpleBase>
    );
};

export { Store };
