import { useTheme, useMediaQuery } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const css = {
        sxBoxContainer: {
            'padding': matches ? '1.875rem' : '0.9375rem',
            'height': '100%',
            'width': '100%',
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': matches ? 'normal' : 'center',
            'position': 'relative',
        },
        sxBoxLogo: {
            padding: '1.125rem 0',
            height: '5rem',
            maxWidth: '11.625rem',
            width: '100%',
            display: 'flex',
            justifyContent: matches ? 'start' : 'center',
            marginTop: matches ? '0' : '5.625rem',
        },
        sxLoginText: {
            fontSize: matches ? '2.25rem' : '1.75rem',
            textAlign: matches ? 'start' : 'center',
            fontWeight: matches ? typography.fontWeightBold || 700 :
                600,
        },
        sxIconButtonEye: {
            fontSize: '1rem',
            padding: '1.25rem 0.9375rem',
            color: palette.dark,
        },
        sxLink: {
            'textDecoration': 'none',
            'color': palette.dark,
            'padding': '0.3125rem 0.625rem',

        },
        sxLinkCreate: {
            'textDecoration': 'none',
            'color': palette.dark,
            'padding': '0.3125rem 0.625rem',
            'marginLeft': matches ? '3.75rem' : '0',
            'marginTop': matches ? '0' : '0.625rem',
        },
        sxTextLink: {
            'fontWeight': typography.fontWeightMedium,
            'fontSize': '0.875rem',
            '&:hover': {
                'textDecoration': 'underline',
            },
        },
        sxTextCreate: {
            'fontWeight': typography.fontWeightSemiBold,
            'fontSize': '0.8125rem',
            '&:hover': {
                'textDecoration': 'underline',
            },
            'whiteSpace': 'nowrap',
        },
        sxIconLink: {
            fontSize: '0.75rem',
            marginLeft: '0.375rem',
        },
        sxButton: {
            'fontSize': '0.9375rem',
            'color': palette.common.white,
            'backgroundColor': palette.bluePortal,
            '&:hover': {
                'backgroundColor': palette.bluePortal,
            },
            'borderRadius': '0',
            'width': '13.125rem',
        },
        sxBoxForm: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: matches ? 'normal' : 'center',
            padding: matches ? '2.5rem 3.25rem 0 12.875rem' : '1.875rem 0',
        },
        sxBoxButtonForm: {
            paddingTop: '1.25rem',
            display: 'flex',
            flexDirection: matches ? 'row' : 'column',
            alignItems: 'center',
            justifyContent: matches ? 'space-between' : 'center',
        },
        sxBoxSideImage: {
            height: '100%',
            maxWidth: '30rem',
            width: '100%',
        },
        sxBoxGeneral: {
            height: '100vh',
            display: 'flex',
        },
    };

    return { css };
};
