import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBoxWrap: {
            display: 'flex',
            position: 'relative',
            height: '2.25rem',
        },
        sxBoxOverlay: {
            border: `0.0625rem solid ${palette.clouds}`,
            borderRadius: '0.25rem',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            color: palette.midnight,
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sxText: {
            textTransform: 'uppercase',
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightMedium || 500,
            whiteSpace: 'nowrap',
        },
    };

    return { css };
};
