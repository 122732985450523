import React from 'react';

import {
    Box,
    Divider,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    ActionButton,
    FormItem,
    Layout,
    MenuItem as MenuComponent,
} from '@components';

import { sx } from './sx';
import { Form } from './styles';

const subject = [
    {
        value: 'Campanhas',
    },
    {
        value: 'Loja',
    },
    {
        value: 'Produtos',
    },
];

const ContactUs: React.FC = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const { css } = sx();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout.SimpleBase
            title='Fale Conosco'
            legend={
                'Envie-nos uma mensagem e retornaremos ' +
                        'o mais breve possível.'}>
            <Box sx={{ ...css.sxBox }}>
                <Grid container spacing={matches ? 6 : 3}>
                    <Grid item md={8} lg={8} sm={12} xs={12}>
                        <Form>
                            <Stack
                                divider={ matches ? <Divider
                                    sx={{ ...css.sxDivider }}
                                    orientation="horizontal"
                                    flexItem /> : <></>}
                                spacing={1}>
                                <FormItem htmlFor='name' label={'Nome'}>
                                    <TextField id='name'
                                        defaultValue={'Amanda Monteiro'}/>
                                </FormItem>
                                <FormItem htmlFor='email'
                                    label={'E-mail'}>
                                    <TextField id='email'
                                        defaultValue={'amanda@gmail.com'}/>
                                </FormItem>
                                <FormItem htmlFor='subject' label={'Assunto'}>
                                    <TextField select
                                        defaultValue={subject[0].value}
                                        id='civil'>
                                        {subject.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                sx={{
                                                    ...css.sxMenuItem }}>
                                                {option.value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormItem>
                                <FormItem htmlFor='cellphone'
                                    label={'Telefone'}>
                                    <TextField id='cellphone'
                                        defaultValue={'(42) 95544 - 5445'}/>
                                </FormItem>
                                <FormItem
                                    htmlFor='message' label={'Mensagem'}>
                                    <TextField id='cellphone'
                                        multiline
                                        rows={4}
                                        defaultValue={
                                            // eslint-disable-next-line max-len
                                            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a felis magna. Suspendisse lorem mi, varius vel interdum ut, accumsan eget eros. Aenean porttitor.' }/>
                                </FormItem>
                                <Box sx={{ ...css.sxBoxButton }}>
                                    <ActionButton
                                        size={'medium'}
                                        text={'Enviar mensagem'}
                                        backgroundColor={'#6115DD'} />
                                </Box>
                            </Stack>
                        </Form>
                    </Grid>
                    <Grid item md={4} lg={4} sm={12} xs={12}>
                        <Stack
                            spacing={ matches ? 1 : 0}>
                            <Typography
                                sx={{ ...css.sxFaqTitle }}
                                variant='h3'>
                                    Consulte o FAQ
                            </Typography>
                            <Typography
                                sx={{ ...css.sxFaqLegend }}
                                variant='caption'>
                                    Visite nossa central de ajuda,
                                    sua resposta pode estar lá.
                            </Typography>
                            <Box sx={{ ...css.sxBoxCentralButton }}>
                                <MenuComponent
                                    borderType={'none'}
                                    mode={'simple'}
                                    backgroundColor={'#ECF0F1'}
                                    onClick={() =>
                                        navigate('/central-de-ajuda')}
                                    title={'Central de ajuda'}>
                                    <Box sx={{ ...css.sxBoxIcon }}>
                                        <I icon={['far', 'chevron-right']} />
                                    </Box>
                                </MenuComponent>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Layout.SimpleBase>
    );
};

export { ContactUs };
