export const filtersData = [
    {
        id: 'categoria',
        filterName: 'Categoria',
        filtersList: [
            {
                id: 'f27ab82ae31c',
                name: 'Equipamentos',
            },
            {
                id: '26d6e5fc8248',
                name: 'Som automotivo',
            },
            {
                id: '80eef226a43b',
                name: 'Ferramentas e Peças',
            },
            {
                id: '227ff998aa54',
                name: 'Pneus para Carros',
            },
            {
                id: '303e1330204b',
                name: 'Eletrônicos',
            },
            {
                id: '55d78a1bc449',
                name: 'Smartphones',
            },
            {
                id: 'a56eb6aaa2da',
                name: 'Eletrodomesticos',
            },
        ],
    },
    {
        id: 'marcas',
        filterName: 'Marca',
        filtersList: [
            {
                id: 'e4d087e083d6',
                name: 'John John',
            },
            {
                id: 'aaf7c95e0d2b',
                name: 'Calvin Klein',
            },
            {
                id: 'bf83f01f88b1',
                name: 'Aramis',
            },
            {
                id: '0726339f844a',
                name: 'Lacoste',
            },
            {
                id: '6932eaf330ce',
                name: 'Reserva',
            },
            {
                id: 'eaa9aee454ed',
                name: 'Colcci',
            },
            {
                id: '22b5292b3dd8',
                name: 'Boss',
            },
        ],
    },
    {
        id: 'tipo-produto',
        filterName: 'Tipo de Produto',
        filtersList: [
            {
                id: 'bd7dae6ff2d1',
                name: 'Camisas',
            },
            {
                id: '2ed18f17bc52',
                name: 'Calças',
            },
            {
                id: '493cf2b7fd8d',
                name: 'Acessórios',
            },
            {
                id: 'cbb4d360b53e',
                name: 'Bolsas',
            },
            {
                id: '1c4486ab6c4d',
                name: 'Calçados',
            },
            {
                id: '6d17b41abc59',
                name: 'Perfumes',
            },
            {
                id: '647b8bb67a91',
                name: 'Jóias',
            },
        ],
    },
    {
        id: 'tamanho',
        filterName: 'Tamanho',
        filtersList: [
            {
                id: '8421fe3b390d',
                name: 'P',
            },
            {
                id: 'eef5b718422b',
                name: 'M',
            },
            {
                id: '50e4f81cc1d2',
                name: 'G',
            },
            {
                id: '32512cbdf278',
                name: 'GG',
            },
            {
                id: 'b015b13cfb15',
                name: '35',
            },
            {
                id: 'f71bd862c7dd',
                name: '36',
            },
            {
                id: '00295659dc56',
                name: '37',
            },
        ],
    },
];

export const orderData = [
    {
        id: 'ordenar',
        filterName: 'Ordenar por',
        filtersList: [
            {
                id: 'f27ab82ae31t',
                name: 'Mais recentes',
            },
            {
                id: 'f27ab82ed31t',
                name: 'Mais relevantes',
            },
            {
                id: '26d6e5fc8245',
                name: 'Menor preço',
            },
            {
                id: '80eef226a4ib',
                name: 'Maior preço',
            },
        ],
    },
];
