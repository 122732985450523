import React from 'react';

import { Box, Typography, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    BoxContent,
    Points,
} from '@components';
import { ProductInfoProps } from '@interfaces';


import { sx } from './sx';

const ProductInfo: React.FC<ProductInfoProps> = ({
    image, name, description, points, children, status,
}: ProductInfoProps) => {
    const { css } = sx();
    const matches = useMediaQuery('(min-width:600px)');
    const { palette } = useTheme();

    const handleStatus = {
        approved: {
            color: palette.nephritis,
            message: 'Pedido aprovado',
        },
        waiting: {
            color: palette.peach,
            message: 'Aguardando pagamento',
        },
    };

    return (
        <BoxContent
            image={image}
            details={
                <Box>
                    <Stack sx={{ ...css.sxStack }}
                        spacing={matches ? 1 : 0}>
                        <Typography
                            sx={{ ...css.sxName }}
                            variant='h4'>
                            {name}
                        </Typography>
                        <Typography
                            sx={{ ...css.sxDescription }}
                            variant='body1'>
                            {description}
                        </Typography>
                        {points &&
                            <Points size={'medium'} value={points} />
                        }
                        {status &&
                            <Typography
                                sx={{ ...css.sxStatus }}
                                variant='caption'>
                                <I
                                    style={{
                                        ...css.sxStatusIcon,
                                        color: handleStatus[status].color }}
                                    icon={['fas', 'circle']} />
                                {handleStatus[status].message}
                            </Typography>
                        }
                    </Stack>
                </Box>
            }
        >
            {children}
        </BoxContent>
    );
};

export { ProductInfo };
