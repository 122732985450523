import { useTheme } from '@mui/material';

export const sx = () => {
    const { typography, palette } = useTheme();

    const css = {
        sxButton: {
            '&:hover': {
                border: 0,
                backgroundColor: 'transparent',
                textDecoration: 'underline',
            },
            ...{
                fontSize: 12,
                fontWeight: typography.fontWeightMedium || 500,
                color: palette.carminePink,
                border: 0,
                borderRadius: 0,
                minWidth: '6.125rem',
                whiteSpace: 'nowrap',
                width: 'auto',
                textTransform: 'none',
            },
        },
    };

    return { css };
};
