import { useTheme } from '@mui/material';

interface sxProps {
    backgroundColor?: string;
};

export const sx = ({
    backgroundColor,
}: sxProps) => {
    const { typography, palette } = useTheme();

    const css = {
        sxBox: {
            default: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                backgroundColor: backgroundColor,
            },
            menuIcon: {
                padding: '0.25rem 0',
                minWidth: '17.5rem',
            },
            dashboard: {},
            simple: {
                width: '100%',
                borderRadius: '0.25rem',
                paddingLeft: '0.9375rem',
            },
        },
        sxBoxContent: {
            display: 'flex',
            alignItems: 'center',
        },
        sxBoxChildren: {
            padding: ' 0 0.9375rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sxBoxIcon: {
            menuIcon: {
                backgroundColor: palette.clouds,
                height: '2.25rem',
                width: '2.25rem',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '0.9375rem',
                marginRight: '0.625rem',
            },
            dashboard: {
                backgroundColor: 'transparent',
                height: '3.6875rem',
                width: '3.75rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '0.3125rem',
            },
            simple: {},
        },
        sxTitle: {
            menuIcon: {
                fontSize: '0.875rem',
                fontWeight: typography.fontWeightRegular,
            },
            dashboard: {
                fontSize: '0.875rem',
                fontWeight: typography.fontWeightMedium,
            },
            simple: {
                fontSize: '0.875rem',
                fontWeight: backgroundColor ? typography.fontWeightMedium :
                    typography.fontWeightRegular,
            },
        },
        sxBackIcon: {
            menuIcon: {
                fontSize: '1rem',
                padding: '0.625rem 0.5625rem',
            },
            dashboard: {
                backgroundColor: 'transparent',
                fontSize: '1.25rem',
                paddingLeft: '0.625rem',
            },
            simple: {
            },
        },
        sxBorder: {
            bottom: {
                borderBottom: `0.0625rem solid ${palette.clouds}`,
            },
            none: {
                border: 'none',
            },
        },
    };

    return { css };
};
