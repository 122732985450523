import React from 'react';

import {
    Box,
    Stack,
    Grid,
    Tabs,
    Tab,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import { Layout, MenuItem } from '@components';

import { sx } from './sx';
import { tabsData } from '@static/profile-links';

const Base: React.FC = () => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const navigate = useNavigate();
    const location = useLocation();
    const { css } = sx();

    const handleActiveTab = () => {
        switch (location.pathname) {
        case '/meus-dados/perfil':
            return 0;
        case '/meus-dados/senha':
            return 1;
        default:
            return 0;
        }
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout.AuthenticatedContent
            title='Meus Dados'
            legend='Gerencie os dados pessoais da sua conta'>
            {!matches &&
                <Tabs
                    value={handleActiveTab()}
                    aria-label="basic tabs example"
                    sx={{ ...css.sxTabs }}>
                    {tabsData.map(({ label, path }) => (
                        <Tab
                            key={label}
                            label={label}
                            onClick={() => navigate(path)}/>
                    ))}
                </Tabs>}
            <Box sx={{ ...css.sxBox }}>
                <Grid container spacing={matches ? 4 : 2}>
                    {matches &&
                        <Grid item md={3} lg={4} sm={12} xs={12}>
                            <Stack
                                spacing={2}>
                                {tabsData.map(({ label, path }) => (
                                    <MenuItem
                                        key={label}
                                        borderType={'none'}
                                        mode={'simple'}
                                        backgroundColor={
                                            location.pathname.match(path) ?
                                                palette.clouds : ''}
                                        onClick={() =>
                                            navigate(path)}
                                        title={label}>
                                        <Box sx={{ ...css.sxBoxIcon }}>
                                            <I icon={
                                                ['far', 'chevron-right']} />
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Stack>
                        </Grid>}
                    <Grid item md={9} lg={8} sm={12} xs={12}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Box>
        </Layout.AuthenticatedContent>
    );
};

export { Base };
