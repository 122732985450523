import React from 'react';
import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { BgImage, Form, Logo, SideImage } from './styles';
import { sx } from './sx';
import { Link } from 'react-router-dom';

const Login: React.FC = () => {
    const { css } = sx();
    const [showPassword, setShowPassword] = React.useState(false);
    const matches = useMediaQuery('(min-width:900px)');
    return (
        <Box sx={{ ...css.sxBoxGeneral }}>
            {matches &&
                <Box sx={{ ...css.sxBoxSideImage }}>
                    <SideImage src="https://cdn.discordapp.com/attachments/1044241266134745202/1085547018275012668/banner-home.png" alt="" />
                </Box>
            }
            <Box sx={{ ...css.sxBoxContainer }}>
                {!matches &&
                    <BgImage src="https://cdn.discordapp.com/attachments/1044241266134745202/1085547018275012668/banner-home.png" alt="" />
                }
                <Box sx={{ ...css.sxBoxLogo }}>
                    <Logo src={'https://cdn.discordapp.com/attachments/1044241266134745202/1082261363788877845/portal-logo.png'} alt='' />
                </Box>
                <Box sx={{ ...css.sxBoxForm }}>
                    <Form action="">
                        <Typography
                            sx={{ ...css.sxLoginText }}
                            variant='h2'>
                            Fazer login
                        </Typography>
                        <TextField
                            focused={true}
                            label={'Login'}
                            placeholder='Login'
                            id="outlined-basic"
                            variant="outlined" />
                        <TextField
                            focused={true}
                            type={showPassword ? 'text' : 'password'}
                            label={'Senha'}
                            placeholder='Senha'
                            id="outlined-basic"
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setShowPassword(!showPassword)}
                                            sx={{ ...css.sxIconButtonEye }}>
                                            {showPassword ?
                                                <I icon={
                                                    ['fas', 'eye-slash']} /> :
                                                <I icon={['fas', 'eye']} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                        <Link to='#!' style={{ ...css.sxLink }}>
                            <Typography
                                sx={{ ...css.sxTextLink }} variant='caption'>
                                Esqueci minha senha
                                <I
                                    style={{ ...css.sxIconLink }}
                                    icon={['fas', 'chevron-right']} />
                            </Typography>
                        </Link>
                        <Box sx={{ ...css.sxBoxButtonForm }}>
                            <a href="/">
                                <Button
                                    sx={{ ...css.sxButton }}>
                                    Entrar
                                </Button>
                            </a>
                            <Link to='#!' style={{ ...css.sxLinkCreate }}>
                                <Typography
                                    sx={{ ...css.sxTextCreate }}
                                    variant='caption'>
                                    Criar conta
                                    <I
                                        style={{ ...css.sxIconLink }}
                                        icon={['fas', 'arrow-right']} />
                                </Typography>
                            </Link>
                        </Box>
                    </Form>
                </Box>
            </Box>
        </Box>
    );
};

export { Login };
