import React from 'react';
import {
    Box,
    TextField,
    Stack,
    Divider,
    useMediaQuery,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    FormItem,
    ActionButton,
} from '@components';
import { Form } from '../styles';
import { sx } from './sx';

const Password: React.FC = () => {
    const matches = useMediaQuery('(min-width:900px)');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const { css } = sx();
    return (
        <Box>
            <Form>
                <Stack
                    divider={ matches ? <Divider
                        sx={{ ...css.sxDivider }}
                        orientation="horizontal"
                        flexItem /> : <></>}
                    spacing={1}>
                    <FormItem htmlFor='current-pass' label={'Senha atual'}>
                        <TextField
                            id='current-pass'
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setShowPassword(!showPassword)}
                                            sx={{ ...css.sxIconButtonEye }}>
                                            {showPassword ?
                                                <I icon={
                                                    ['fas', 'eye-slash']} /> :
                                                <I icon={['fas', 'eye']} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </FormItem>
                    <FormItem htmlFor='new-pass' label={'Nova senha'}>
                        <TextField
                            id='new-pass'
                            type={showNewPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowNewPassword(
                                                !showNewPassword)}
                                            sx={{ ...css.sxIconButtonEye }}>
                                            {showNewPassword ?
                                                <I icon={
                                                    ['fas', 'eye-slash']} /> :
                                                <I icon={['fas', 'eye']} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </FormItem>
                    <FormItem htmlFor='retype-pass' label={'Repita senha'}>
                        <TextField
                            id='retype-pass'
                            type={showNewPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowNewPassword(
                                                !showNewPassword)}
                                            sx={{ ...css.sxIconButtonEye }}>
                                            {showNewPassword ?
                                                <I icon={
                                                    ['fas', 'eye-slash']} /> :
                                                <I icon={['fas', 'eye']} />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }} />
                    </FormItem>
                    <Box sx={{ ...css.sxBoxButton }}>
                        <ActionButton
                            size={'medium'}
                            text={'Salvar alterações'}
                            backgroundColor={'#6115DD'} />
                    </Box>
                </Stack>
            </Form>
        </ Box>
    );
};

export { Password };
