export const ordersData = [
    {
        name: 'Wap',
        description: 'Lavadora de Alta Pressão Wap Eco Fit 2200 1500psi',
        points: '371.00',
        status: 'waiting',
        quantity: 4,
    },
    {
        name: 'Wap',
        description: 'Cadeira Gamer Prizi - JX-1039',
        points: '489.00',
        status: 'waiting',
        quantity: 6,
    },
    {
        name: 'Wap',
        description: 'Console Xbox Series S 512GB SSD',
        points: '189.00',
        status: 'approved',
        quantity: 5,
    },
];

export const tabsData = [
    {
        label: 'Em aberto',
    },
    {
        label: 'Concluídos',
    },
];
