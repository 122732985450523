import { useTheme, useMediaQuery } from '@mui/material';

interface SxProps {
    points: number;
    expire?: boolean;
}
export const sx = ({
    points, expire,
}: SxProps) => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const css = {
        sxDivider: {
            height: '3.125rem',
            borderColor: palette.dark,
            width: '0.125rem',
            borderWidth: '0rem 0.125rem 0rem 0rem',
            padding: '0 0.25rem',
        },
        sxBoxContainer: {
            width: '100%',
        },
        sxBoxInfo: {
            display: 'flex',
            alignItems: 'center',
        },
        sxIcon: {
            fontSize: matches ? '1.25rem' : '1.125rem',
            color: palette.dark,
            marginRight: '1.25rem',
        },
        sxDate: {
            fontWeight: typography.fontWeightRegular || 400,
            fontSize: matches ? '0.875rem' : '0.75rem',
        },
        sxPlace: {
            fontWeight: typography.fontWeightBold || 700,
            fontSize: matches ? '0.875rem' : '0.75rem',
        },
        sxPoints: {
            paddingLeft: '0.9375rem',
            fontWeight: typography.fontWeightSemiBold || 600,
            fontSize: matches ? '0.875rem' : '0.75rem',
            textAlign: 'end',
            color: expire ? palette.dark :
                points >= 0 ? palette.nephritis : palette.carminePink,
        },
    };
    return { css };
};
