import styled from 'styled-components';

export const Input = styled.input`
    font-size: 1rem;
    font-family: 'Poppins';
    margin-right: 0.625rem;
    width: 100%;
    border: none;
    outline: 0;

    @media (max-width: 600px) {
        font-size: 0.875rem;
    }

`;
