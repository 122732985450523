import React from 'react';
import { Box, Stack } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { HeaderPage, MenuItem } from '@components';
import { sx } from './sx';
import { useNavigate } from 'react-router-dom';
import { tabsData } from '@static/menus';

const MenuDefault: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();
    return (
        <Box sx={{ ...css.sxBoxPage }}>
            <HeaderPage
                onBack={() => navigate(-1)}
                title={'Menu'} />
            <Stack sx={{ ...css.sxStack }}>
                {tabsData.map(({
                    label, path,
                }, i) => (
                    <MenuItem
                        key={`${label}-${i}`}
                        mode={'simple'}
                        borderType={'bottom'}
                        title={label}
                        onClick={() => navigate(path)}>
                        <I
                            style={{ ...css.sxIconMenu }}
                            icon={
                                ['far', 'chevron-right']}
                        />
                    </MenuItem>
                ))}
            </Stack>
        </Box>
    );
};

export { MenuDefault };
