import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Logo } from './styles';
import { sx } from './sx';

const Footer: React.FC = () => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxContainer }}>
            <Container maxWidth='lg'>
                <Box sx={{ ...css.sxBox }}>
                    <Box sx={{ ...css.sxBoxImg }}>
                        <Logo
                            src="https://cdn.discordapp.com/attachments/1044241266134745202/1082718937868947518/logo.png"
                            alt="Commarket" />
                    </Box>
                    <Box sx={{ ...css.sxBoxInfo }}>
                        <Typography
                            sx={{ ...css.sxTextInfo }}
                            variant='caption'>
                                Copyright © 2023 Commarket LTDA
                        </Typography>
                        <Box sx={{ ...css.sxBoxLinks }}>
                            <Link style={{ ...css.sxLink }} to={''}>
                                <Typography
                                    sx={{ ...css.sxTextInfo }}
                                    className={'link'}
                                    variant='caption'>
                                Privacidade
                                </Typography>
                            </Link>
                            <Typography
                                sx={{ ...css.sxTextInfo }}
                                className={'divisor'}
                                variant='caption'>
                                ·
                            </Typography>
                            <Link style={{ ...css.sxLink }} to={''}>
                                <Typography
                                    sx={{ ...css.sxTextInfo }}
                                    className={'link'}
                                    variant='caption'>
                                Termos
                                </Typography>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export { Footer };
