import { useTheme } from '@mui/material';

export const customMargin = {
    bottom: {
        extraBig: '0.4375rem',
        big: '0.4375rem',
        medium: '0.3125rem',
        small: '0.1875rem',
        tiny: '0.125rem',
        extraTiny: '0.125rem',
    },
    right: {
        extraBig: '0',
        big: '0',
        medium: '0',
        small: '0',
        tiny: '0',
        extraTiny: '0',
    },
};

export const handleBorderWidth = {
    'none': '0rem',
    '1x': '0.0625rem',
    '2x': '0.125rem',
};

export const avatarSizes ={
    extraBig: {
        width: 98,
        height: 98,
    },
    big: {
        width: 70,
        height: 70,
    },
    medium: {
        width: 56,
        height: 56,
    },
    small: {
        width: 48,
        height: 48,
    },
    tiny: {
        width: 38,
        height: 38,
    },
    extraTiny: {
        width: 32,
        height: 32,
    },
};

export const fontSizeTitle = {
    extraBig: '1rem',
    big: '1rem',
    medium: '0.875rem',
    small: '0.875rem',
    tiny: '0.875rem',
    extraTiny: '0.875rem',
};

export const fontSizeLegend = {
    extraBig: '0.875rem',
    big: '0.75rem',
    medium: '0.75rem',
    small: '0.75rem',
    tiny: '0.6875rem',
    extraTiny: '0.6875rem',
};

interface handleBorderProps {
    borderWidth?: 'none' | '1x' | '2x';
    borderColor?: string;
}

export const handleBorder = ({
    borderWidth, borderColor,
}: handleBorderProps) => {
    const { palette } = useTheme();

    return `${ borderWidth ?
        handleBorderWidth[borderWidth] : '0.0625rem'}
        solid ${borderColor ? borderColor : palette.common.black}`;
};

export const sx = () => {
    const css= {
        sxAvatarGroup: {
            default: {
                display: 'flex',
                alignItems: 'center',
            },
            right: {
                flexDirection: 'row',
            },
            bottom: {
                flexDirection: 'column',
            },
        },
        sxAvatarDefault: {
            cursor: 'pointer',
        },
        sxBoxAvatarInfo: {
            right: {
                marginLeft: '0.625rem',
                alignItems: 'start',
            },
            bottom: {
                alignItems: 'center',
            },
            default: {
                display: 'flex',
                flexDirection: 'column',
            },
        },

    };
    return { css };
};
