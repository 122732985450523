import React from 'react';

import {
    Box,
    Typography,
    Grid,
    Stack,
} from '@mui/material';

import {
    ActionButton,
    Layout,
    Points,
    ProductInfo,
} from '@components';

import { ControllQuantity } from './components/controll-quantity';
import { sx } from './sx';
import { ordersData } from '@static/orders';
import { ProductProps } from '@interfaces';
import { Link } from 'react-router-dom';

const Cart: React.FC = () => {
    const { css } = sx();
    const [items, setItems] = React.useState<ProductProps[]>(ordersData);

    const removeItem = (product: ProductProps) => {
        if (product.quantity > 1) {
            product.quantity -= 1;
            const update = items.map((item) => {
                if (item.description === product.description) {
                    return product;
                } else {
                    return item;
                }
            });
            setItems(update);
        };
    };

    const addItem = (product: ProductProps) => {
        product.quantity += 1;
        const update = items.map((item) => {
            if (item.description === product.description) {
                return product;
            } else {
                return item;
            }
        });
        setItems(update);
    };

    return (
        <Layout.SimpleBase>
            <Box sx={{ ...css.sxBox }}>
                <Typography variant='h2'
                    sx={{ ...css.sxTitle }}>
                    Carrinho de compras
                </Typography>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item md={8} lg={8} sm={12} xs={12}>
                            <Stack
                                sx={{ ...css.sxStackItems }}
                                spacing={2}>
                                {items.map((product) => (
                                    <Box
                                        sx={{ ...css.sxBoxItem }}
                                        key={product.description}>
                                        <ProductInfo
                                            image='https://cdn.discordapp.com/attachments/1044241266134745202/1082652690439487568/mc.png'
                                            name={product.name}
                                            description={product.description}
                                            points={product.points}>
                                            <Box sx={{ ...css.sxBoxActions }}>
                                                <ControllQuantity
                                                    value={product.quantity}
                                                    remove={() => {
                                                        removeItem(product);
                                                    }}
                                                    add={() => {
                                                        addItem(product);
                                                    }} />
                                                <Link
                                                    to={''}
                                                    style={{ ...css.sxLink }}>
                                                    <Typography
                                                        sx={{
                                                            ...css.sxLinkText }}
                                                        variant='caption'>
                                                            Remover
                                                    </Typography>
                                                </Link>
                                            </Box>
                                        </ProductInfo>
                                    </Box>
                                ))}
                            </Stack>
                        </Grid>
                        <Grid item md={4} lg={4} sm={12} xs={12}>
                            <Box sx={{ ...css.sxSummaryBox }}>
                                <Box sx={{ ...css.sxResumeTextBox }}>
                                    <Typography
                                        sx={{ ...css.sxResumeText }}
                                        variant='h3'>
                                        Resumo de carrinho
                                    </Typography>
                                </Box>
                                <Stack
                                    sx={{ ...css.sxSubtotalStack }}
                                    spacing={1}>
                                    <Typography
                                        sx={{ ...css.sxSubtotalText }}
                                        variant='h4'>
                                            Subtotal
                                    </Typography>
                                    <Points size={'big'} value={'371.00'} />
                                </Stack>
                                <ActionButton
                                    size='big'
                                    text={'Finalizar compra'}
                                    backgroundColor={'#008732'} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Layout.SimpleBase>
    );
};

export { Cart };
