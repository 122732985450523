import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette } = useTheme();
    const css = {
        sxBox: {
            paddingRight: matches ? '1.875rem' : '0.9375rem',
            display: 'flex',
            alignItems: 'center',
        },
        sxIconButton: {
            color: palette.dark,
            padding: matches ? '1.4375rem 1.875rem' : '1rem 0.8125rem',
            borderRadius: '0',
        },
        sxIconSearch: {
            fontSize: matches ? '1.5rem' : '1.125rem',
        },
    };

    return { css };
};
