import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

import { FilterButtonProps } from '@interfaces';

import { Label } from './styles';
import { sx } from './sx';

const FilterButton: React.FC<FilterButtonProps> = ({
    id, filter, children, position,
}: FilterButtonProps) => {
    const { css } = sx();
    const { palette } = useTheme();
    return (
        <Box sx={{ ...css.sxBoxWrap }}>
            <Label backgroundColor={palette.bluePortal} htmlFor={id}>
                {filter}
                {children}
                <Box sx={{ ...css.sxBoxOverlay }}
                    className={`overlay ${position}`}>
                    <Typography
                        sx={{ ...css.sxText }}
                        variant='caption'>
                        {filter}
                    </Typography>
                </Box>
            </Label>
        </Box>
    );
};

export { FilterButton };
