import React from 'react';

import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { ActionButton } from '@components';
import { FilterSectionProps } from '@interfaces';

import { sx } from './sx';

const FilterSection: React.FC<FilterSectionProps> = ({
    filterName, children, buttonAction,
}: FilterSectionProps) => {
    const [expand, setExpand] = React.useState(false);
    const { css } = sx(expand);
    return (
        <Box sx={{ ...css.sxBox }}>
            <Stack
                divider={
                    <Divider
                        sx={{ ...css.sxDivider }}
                        orientation='horizontal'/>
                }>
                <Box
                    onClick={() => setExpand(!expand)}
                    sx={{ ...css.sxBoxTitle }}>
                    <Typography
                        sx={{ ...css.sxFilterName }}
                        variant='h3'>
                        {filterName}
                    </Typography>
                    <IconButton sx={{ ...css.sxIcon }}>
                        <I icon={!expand ? ['far', 'chevron-down'] :
                            ['far', 'chevron-up']} />
                    </IconButton>
                </Box>
                <Stack
                    className={expand ? 'show' : 'hide'}
                    sx={ css.sxStack }
                    divider={
                        <Divider
                            sx={{ ...css.sxDivider }}
                            orientation='horizontal'/>
                    }>
                    {children}
                </Stack>
                {expand &&
                    <Box sx={{ ...css.sxBoxButton }}>
                        <ActionButton
                            size='medium'
                            icon={['fas', 'check']}
                            onClick={buttonAction}
                            text={'Aplicar'}
                            backgroundColor={'#4950E6'} />
                    </Box>
                }

            </Stack>
        </Box>
    );
};

export { FilterSection };

