import React from 'react';
import {
    Box,
    Typography,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { BoxSectionProps } from '@interfaces';

import { sx } from './sx';
import { Link } from 'react-router-dom';

const BoxSection: React.FC<BoxSectionProps> = ({
    title, legend, children, seeMore, seeMoreLink,
}: BoxSectionProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.sxBox }}>
            {title && legend &&
            <Box sx={{ ...css.sxBoxInfo }}>
                <Box>
                    <Typography
                        sx={{ ...css.sxTitle }}
                        variant='h2'>
                        {title}
                    </Typography>
                    <Typography
                        sx={{ ...css.sxLegend }}
                        variant='caption'>
                        {legend}
                    </Typography>
                </Box>
                {seeMore &&
                    <Link
                        style={{ ...css.sxSeeMoreLink }}
                        to={seeMoreLink ? seeMoreLink : '#!'}>
                        <Typography
                            variant='caption'
                            sx={{ ...css.sxSeeMoreText }}>
                            Ver mais
                        </Typography>
                        <I
                            style={{ ...css.sxSeeMoreIcon }}
                            icon={['far', 'chevron-right']} />
                    </Link>
                }
            </Box>
            }
            {children}
        </Box>
    );
};

export { BoxSection };
