import React from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface ExtractProps {
    clientColor: string;
}

import { sx } from './sx';

const Extract: React.FC<ExtractProps> = ({
    clientColor,
}: ExtractProps) => {
    const { css } = sx({
        clientColor: clientColor,
    });
    return (
        <Link to={'/extrato'}
            style={{ ...css.sxLink }}>
            <I
                style={{ ...css.sxIcon }}
                icon={['fal', 'clipboard-list-check']} />
            <Box sx={{ ...css.sxBoxInfo }}>
                <Typography variant='h2'
                    sx={{ ...css.sxTitle }}>
                        Extrato
                    <I
                        style={{ ...css.sxArrowIcon }}
                        icon={['far', 'arrow-right-long']} />
                </Typography>
                <Typography variant='body1'
                    sx={{ ...css.sxLegend }}>
                        Visualize seu extrato
                </Typography>
            </Box>
        </Link>
    );
};

export { Extract };
