import React from 'react';

import {
    Button,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { ActionButtonProps } from '@interfaces';

import { sx } from './sx';

const ActionButton: React.FC<ActionButtonProps> = ({
    text, backgroundColor, size, icon, onClick,
}: ActionButtonProps) => {
    const { css } = sx({
        backgroundColor: backgroundColor,
    });
    return (
        <Button onClick={onClick} sx={{
            ...css.sxButton['default'],
            ...css.sxButton[size ? size : 'small'],
        }}>
            {text}
            {icon &&
                <I
                    style={{ ...css.sxIcon[size ? size : 'small'] }}
                    icon={icon} />
            }
        </Button>
    );
};

export { ActionButton };
