export const quizData = [
    {
        title: 'Aceleração Média e MUV',
        // eslint-disable-next-line max-len
        legend: 'Quiz com exercícios sobre aceleração média e movimento uniformemente variado (MUV).',
        questions: 10,
    },
    {
        title: 'Exercícios sobre Leis de Newton',
        legend: 'Quiz com 10 exercícios sobre aplicações das leis de Newton.',
        questions: 10,
    },
    {
        title: 'Eletrostática - Carga Elétrica e Eletrização',
        legend: 'Quiz com 10 exercícios sobre Carga Elétrica e Eletrização.',
        questions: 10,
    },
    {
        title: 'História da Física',
        legend: 'Quiz com 10 exercícios sobre a História da Física.',
        questions: 10,
    },
];
