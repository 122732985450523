import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();
    const css = {
        sxBoxContainer: {
            padding: matches ? '1.875rem' : '0.9375rem',
            minHeight: '40.1875rem',
        },
        sxLink: {
            padding: matches ? '0.9375rem' : '0.9375rem 0',
            textDecoration: 'none',
            marginBottom: '0.625rem',
        },
        sxTextLink: {
            'fontSize': matches ? '0.9375rem' : '0.875rem',
            'color': palette.bluePortal,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        sxIconLinkBack: {
            fontSize: '1.125rem',
            color: palette.bluePortal,
        },
        sxTitle: {
            fontWeight: typography.fontWeightMedium,
            fontSize: matches ? '1.875rem' : '1.5rem',
            padding: matches ? '0.625rem 0.9375rem' : '0.625rem 0',
        },
        sxDivider: {
            borderColor: palette.clouds,
        },
        sxBoxQuestion: {
            'padding': matches ? '0.9375rem' : '0.9375rem 0',
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'cursor': 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        sxQuestionText: {
            fontSize: matches ? '1rem' : '0.875rem',
            fontWeight: typography.fontWeightRegular,
        },
        sxBoxAnswer: {
            'padding': matches ? '0.9375rem' : '0.9375rem 0',
        },
        sxAnswerText: {
            fontSize: matches ? '1rem' : '0.875rem',
            fontWeight: typography.fontWeightLight,
        },
    };
    return { css };
};
