import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:900px)');
    const { palette, typography } = useTheme();
    const css = {
        sxBoxGrid: {
            padding: matches ? '1.875rem' : '0.9375rem',
        },
        sxBoxStack: {
            padding: matches ? '1.25rem' : '0.3125rem 0',
        },
        sxDivider: {
            borderColor: matches ? palette.clouds : 'transparent',
        },
        sxStackInfo: {
            border: matches ? `1px solid ${palette.clouds}` : 'none',
            maxWidth: matches ? '30.5625rem' : '100%',
        },
        sxBoxStackTable: {
            padding: matches ? '0 1.875rem' : '0 0.9375rem',
            paddingBottom: matches ? '1.875rem' : '0.9375rem',
        },
        sxStackTextInfo: {
            maxWidth: matches ? '22rem' : '100%',
        },
        sxInfoTitle: {
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightSemiBold,
        },
        sxInfoLegend: {
            fontSize: matches ? '1.25rem' : '1rem',
            fontWeight: typography.fontWeightRegular,
        },
        sxDeliveryInfo: {
            fontSize: '0.75rem',
        },
        sxStackButtons: {
            maxWidth: '21.3125rem',
            paddingBottom: matches ? 0 : '1.25rem',
        },
        sxGridSlider: {
            display: matches ? 'block' : 'flex',
        },
        sxLinkBack: {
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            padding: '0.75rem 1rem',
        },
        sxIconBack: {
            fontSize: '1.125rem',
            color: palette.midnight,
            marginRight: '0.5rem',
        },
        sxTextBack: {
            'color': palette.midnight,
            'fontWeight': typography.fontWeightMedium || 500,
            'fontSize': '0.9375rem',
            'textTransform': 'uppercase',
            '&:hover': {
                'textDecoration': 'underline',
            },
        },
        sxTableTitle: {
            fontSize: matches ? '1rem' : '0.875rem',
            fontWeight: typography.fontWeightMedium,
            padding: '0.625rem 0',
        },
    };

    return { css };
};
