import styled from 'styled-components';

export const PartnerLogo = styled.img`
    max-width: 7.875rem;
    height: 100%;
    height: 2.25rem;
    width: auto;
    object-fit: contain;

    @media (max-width: 600px) {
        max-width: 6.625rem;
        height: 1.375rem;
    }
`;
