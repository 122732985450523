import { Box, styled } from '@mui/material';

export const BoxActiveFiltersList = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.common.white,
    overflowX: 'auto',
    paddingBottom: '0.5rem',
}));
