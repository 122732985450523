export const sx = () => {
    const css = {
        sxBox: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        },
        sxBoxImage: {
            display: 'flex',
            position: 'relative',
            width: '100%',
            height: '100%',
            maxHeight: '28.75rem',
            maxWidth: '30.5625rem',
        },
    };
    return {
        ...css,
    };
};
